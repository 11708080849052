import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CheckoutFormSchema } from "../schema/checkoutFormSchema";
import { useLocation, useNavigate } from "react-router-dom";
import { InitCheckout, proceedPaymentUsingPg } from "../API/subscriptionApi";
import { SubscriptionDto } from "../component/SubscriptionPlanList/SubscriptionPlanList";

import { usePaymentCardData, GetPaymentCardDto, useUserMeQuery } from "../API/api";
import { TickIcon } from "../assets/images/AllSvg";

function CheckoutPage() {
  const navigate = useNavigate();
  const { data: userData } = useUserMeQuery();
  console.log("userData 1st", userData);
  const userId = userData?.user.id;
  const userUuid = userData?.user.userId;
  console.log("userId", userId);
  console.log("userUuid", userUuid);
  const location = useLocation();

  const subscriptionDto = location.state as SubscriptionDto;
  console.log("subscriptionDto",subscriptionDto)
  const { data: paymentCardData, isLoading: paymentDataLoading } =
    usePaymentCardData();
  const [showAdditionalImages, setShowAdditionalImages] = useState(false);
  const paymentMethodImages = [
    { src: require("../assets/images/upi.png"), alt: "upi" },
    { src: require("../assets/images/Visa_card.png"), alt: "visa" },
    { src: require("../assets/images/Master_Card.png"), alt: "master" },
    { src: require("../assets/images/AmericanExpress.png"), alt: "am" },
  ];

  const paymentMethodAdditionalImages = [
    { src: require("../assets/images/UnionPay_card.png"), alt: "visa" },
    { src: require("../assets/images/Maestro_card.png"), alt: "upi" },
    { src: require("../assets/images/Cirrus_card.png"), alt: "master" },
  ];

  const {
    register: checkoutForm,
    handleSubmit: CheckoutFormSubmission,
    formState: { errors: CheckoutFormErrors },
  } = useForm({
    resolver: yupResolver(CheckoutFormSchema),
    mode: "onChange",
  });
console.log("CheckoutFormErrors",CheckoutFormErrors)
  type FormData = {
    firstName: string;
    lastName: string;
    address: string;
    appartment: string;
    city: string;
    state: string;
    country: string;
    Email: string;
    pincode: string;
    Phone: string;
  };

  const onSubmit = (data: any) => {
    data = data as FormData;
    const initCheckout: InitCheckout = {
      // buyerAddress: data.address,
      // buyerCity: data.city,
      // buyerCountry: data.country,
      buyerEmail: data.Email,
      buyerFirstName: data.firstName,
      buyerLastName: data.lastName,
      buyerPhone: data.Phone,
      // buyerPinCode: data.pincode,
      // buyerState: data.state,
      userId: userData?.user?.id as number,
      userUuid: userData?.user?.userId as string,
      subscriptionPlanId: subscriptionDto.subscriptionPlanId,
      subscriptionId: subscriptionDto.subscriptionId,
      paymentUuid: subscriptionDto.paymentUUID,
    };
    console.log(initCheckout);
    proceedPaymentUsingPg(initCheckout)
      .then((res) => {
        console.log("responseerrrrr",res);

        console.log(res);
        navigate("/send-to-paygate", { state: res });
      })
      .catch((ex) => {
        console.error(ex);
      });
  };
  const benefits = [
    {
      name: "Unlimited Projects",
    },
    {
      name: "24/7 Support",
    },
    {
      name: "Cloud Storage",
    },
  ];
  

  return (
    <>
      <div className="bg-white mainsubdiv ">
        <div className="container p-3 mx-auto md:p-6 lg:p-6 ">
          <div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-6  ">
            {/* <!-- Form Left Side --> */}
            <form
              className="sm:order-2 order-2 mb-4  xl:mr-5 xl:col-span-3 md:col-span-3 md:order-2  lg:order-2 xl:order-2 "
              onSubmit={CheckoutFormSubmission(onSubmit)}
            >
              <h2 className="block mb-6 text-xl font-bold text-gray-800">
                Billing Address
              </h2>
              <div className="addresDiv  grid grid-cols-6  md:gap-2 lg:gap-2 ">
                <div className="col-span-6 mb-4 md:col-span-6 ">
                  
                </div>
                <div className="col-span-6 mb-4  md:col-span-6  xl:col-span-3 lg:col-span-6">
                  <input
                    {...checkoutForm("firstName")}
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                    className="w-full px-3 py-3 leading-tight text-gray-700 border border-gray-300 rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  />
                  <p className="text-[16px] text-red-600">
                    {CheckoutFormErrors?.firstName?.message}
                  </p>
                </div>

                <div className="col-span-6 mb-4 md:col-span-6 lg:col-span-6 xl:col-span-3">
                  <input
                    className="w-full px-3 py-3 leading-tight text-gray-700 border border-gray-300 rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                    id="lastName"
                    {...checkoutForm("lastName")}
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                  />
                  <p className="text-[16px] text-red-600">
                    {CheckoutFormErrors?.lastName?.message}
                  </p>
                </div>

                <div className="col-span-6 mb-4 md:col-span-6">
                  <input
                    className="w-full px-3 py-3 leading-tight text-gray-700 border border-gray-300 rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                    {...checkoutForm("Email")}
                    id="Email"
                    type="Email"
                    placeholder="Email"
                    name="Email"
                  />
                  <p className="text-[16px] text-red-600">
                    {CheckoutFormErrors?.Email?.message}
                  </p>
                </div>

                <div className="col-span-6 mb-4 md:col-span-6 ">
                  <input
                    className="w-full px-3 py-3 leading-tight text-gray-700 border border-gray-300 rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                    {...checkoutForm("Phone")}
                    id="Phone"
                    type="number"
                    name="Phone"
                    placeholder="Phone"
                  />
                  <p className="text-[16px] text-red-600">
                    {CheckoutFormErrors?.Phone?.message}
                  </p>
                </div>
              </div>
              <div className="cardDiv    gap-2 mt-5 order-1 lg:order-1 md:order-1 xl:order-2">
                <div className="flex flex-col gap-2">
                  <h2 className="text-xl font-bold text-gray-800 ">Payment</h2>{" "}
                  <p className="text-sm text-gray-500">
                    All transactions are secure and encrypted.
                  </p>
                </div>

                <div className="items-center mt-5 bg-gray-100">
                  <div className="flex items-center justify-between gap-2 p-4 border-2 border-blue-600 bg-gray-50">
                    <div className="flex items-center gap-1 md:gap-1 lg:gap-2">
                      <input
                        checked
                        id="default-radio-2"
                        type="radio"
                        value=""
                        name="default-radio"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        htmlFor="default-radio-2"
                        className="text-base font-semibold text-gray-900 ms-2 dark:text-gray-300 "
                      >
                        airpay
                      </label>
                    </div>

                    <div className="flex flex-col items-cente ">
                      <div className="flex">
                        {paymentMethodImages?.map((image, index) => (
                          <img
                            key={index}
                            src={image.src}
                            alt={image.alt}
                            className="w-10 h-auto md:w-12"
                          />
                        ))}
                        <button
                          type="button"
                          onClick={() =>
                            setShowAdditionalImages(!showAdditionalImages)
                          }
                          className="pl-2 pr-2 ml-2 text-blue-600 underline shadow-lg"
                        >
                          {showAdditionalImages
                            ? `-${paymentMethodAdditionalImages?.length}`
                            : `+${paymentMethodAdditionalImages?.length}`}
                        </button>
                      </div>
                      {showAdditionalImages && (
                        <div className="flex mt-2 ">
                          {paymentMethodAdditionalImages?.map(
                            (image, index) => (
                              <img
                                key={index}
                                src={image.src}
                                alt={image.alt}
                                className="w-12 h-auto"
                              />
                            )
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center gap-3 text-center ">
                    <img
                      src={require("../assets/images/payment_card.png")}
                      alt="Air1"
                      className="w-40 h-auto"
                    />
                    <p className="w-4/6 mb-3 text-sm font-semibold text-gray-900">
                      After clicking "Pay now", you will be redirected to airpay
                      to complete your purchase securely.{" "}
                    </p>
                  </div>
                </div>
                <button type="submit" className="w-full px-6 py-2 mt-2 bg-gray-200 hover:bg-gray-500">
                  Pay now
                </button>
              </div>
            </form>

            {/* <!-- Form Right Side --> */}
            <div className="mb-4 xl:ml-5 p-1  xl:order-2  xl:px-8 xl:pt-6 md:px-8 md:pt-6 md:pb-8 lg:col-span-1 xl:col-span-3 md:col-span-1 sm:col-span-1 bg-gray-50">
              <h2 className="block mb-6 text-xl font-bold text-gray-800">
                Payment Details
              </h2>

              <div className="border border-gray-300 shadow-lg">
                {/* new */}
                {paymentCardData?.map((plan: GetPaymentCardDto) => (
                  <div className="flex  shadow-2xl  ">
                    {/* Section 1 */}
                    <div className="justify-center flex-grow section1 md:w-4/6 w-full   ">
                      <div className="relative items-center h-full p-8 overflow-hidden text-center text-white bg-gradient-to-tr from-purple-500 via-pink-500 to-red-500">
                        <div className="mb-4 text-sm font-bold md:text-2xl ">
                          {plan?.name}
                        </div>
                        <p className="mb-4 text-sm text-center">
                          {" "}
                          {plan?.description}
                        </p>
                        <div className="mt-6">
                          <span className="text-lg font-extrabold ">
                            &#8377;{plan?.price}
                          </span>
                          <span className="text-xs ">
                            {" "}
                            / {plan?.validityDays} days validity
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* Section 2 */}
                    <div className="section2 md:w-2/6  w-full  bg-white">
                      <div className="items-center p-1  overflow-hidden    ">
                        <div className="mb-2 text-sm xl:text-base font-bold text-start">
                          {plan?.name}
                        </div>
                        <p className="text-sm text-start text-gray-700">
                          {plan?.description}
                        </p>
                        <div className="mt-4 text-left">
                          <span className="text-sm xl:text-lg font-bold">
                            {" "}
                            &#8377;{plan?.price}
                          </span>
                          <span className="text-[8px] xl:text-xs text-gray-600">
                            / {plan?.validityDays} Days validity
                          </span>
                        </div>
                        <ul className="mt-4 ">
                          {benefits?.map((benefit, index) => (
                            <li
                              key={index}
                              className="flex items-center text-left mt-3 text-[10px]"
                            >
                              <TickIcon />
                              {benefit?.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="mt-2 mb-4">
                <div>
                  <div className="flex flex-col gap-3 p-5 mt-5 border bg-gray-50">
                    <p className="text-gray-600 ">Order Summary</p>
                    <p>Sub Total:&#8377;200</p>
                    <p>Total:&#8377;200</p>
                    <p>including 28.5 in taxes</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CheckoutPage;
