import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ItemDataProvider } from "./component/Context/contextData";

function registerDev() {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.dev.js")
    .then((registration) => {
      //(firebase as any).messaging().useServiceWorker(registration);
    })
    .catch((error) => {
      console.error("Error registering service worker:", error);
    });
}

function registerProd() {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.prod.js")
    .then((registration) => {
      //(firebase as any).messaging().useServiceWorker(registration);
    })
    .catch((error) => {
      console.error("Error registering service worker:", error);
    });
}

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    if (process.env.NODE_ENV === "development") {
      console.log("dev");
      registerDev();
    } else {
      console.log("prod");
      registerProd();
    }
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ItemDataProvider>
      <App />
    </ItemDataProvider>
  </React.StrictMode>
);
