import React from "react";
import { useTranslation } from "react-i18next";
import { AiFillCaretRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const SolutionsandSupport = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="md:px-5 px-2">
      <div className="grid md:grid-cols-2 xl:gap-[67px] gap-[30px] xl:max-w-[1186px] 2xl:mt-[80px] 2xl:max-w-[1440px] mx-auto 4xl:max-w-[1930px]">
        <div className="shadow-box md:p-14  p-4 rounded-[20px] bg-white">
          <h2 className="2xl:text-[47px] xl:text-[40px] text-[32px] mb-2.5 text-center font-bold text-black">
            {t("Solutions")}
          </h2>
          <p className="4xl:text-[35px] xl:text-[27px] text-responsive-base text-black font-medium block text-center">
            {t(
              "Eaidex provides a seamless platform to find and hire reliable professionals for a wide range of home services, ensuring your specific needs are met with efficiency and quality."
            )}
          </p>
          <div className="flex justify-center w-full mt-[60px]">
            <button
              className="flex items-center gap-1.5 justify-center 4xl:text-[1.2vw] text-[17px]  leading-[13px] font-medium py-[14px] px-[24px] rounded-full bg-[#F0A61F]"
              onClick={() => {
                navigate("/solutions");
              }}
            >
              {t("LearnMore")}{" "}
              <AiFillCaretRight className="text-[14px] 4xl:text-[1.2vw]" />
            </button>
          </div>
        </div>
        <div className="shadow-box md:p-14 p-4 rounded-[20px] bg-white flex flex-col justify-between">
          <div>
            <h2 className="2xl:text-[47px] xl:text-[40px] text-[32px] mb-2.5 text-center font-bold text-black">
              {t("Support")}
            </h2>
            <p className="4xl:text-[35px] xl:text-[27px] text-responsive-base text-black font-medium block text-center">
              {t(
                "Our dedicated team is committed to providing reliable assistance and ensuring customer satisfaction throughout your experience with Eaidex's on-demand home services."
              )}
            </p>
          </div>
          <div className="flex justify-center w-full mt-[60px]">
            <button
              className="flex items-center gap-1.5 justify-center 4xl:text-[1.2vw] text-[17px]  leading-[13px] font-medium py-[14px] px-[24px] rounded-full bg-[#F0A61F]"
              onClick={() => {
                navigate("/support");
              }}
            >
              {t("LearnMore")}{" "}
              <AiFillCaretRight className="text-[14px] 4xl:text-[1.2vw]" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolutionsandSupport;
