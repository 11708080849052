import React, { useState, useEffect, useMemo } from "react";
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
import { Service } from "../API/types";
import { capitalizeWords } from "../utils/utils";

interface CategoryGridProps {
  serviceData: Service[];
  searchInput: string;
  searchLabour: (searchId: Service) => void;
  t: (key: string) => string;
  getCompleteImageUrl: (url: string) => string;
}

const CategoryGrid: React.FC<CategoryGridProps> = ({
  serviceData,
  searchInput,
  searchLabour,
  t,
  getCompleteImageUrl,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(28);
  const ITEMS_PER_PAGE = { large: 28, medium: 16, small: 8, extraSmall: 4 };

  // Update items per page based on screen size
  useEffect(() => {
    const updateItemsPerPage = () => {
      let newItemsPerPage: number;
      if (window.innerWidth >= 1920) {
        newItemsPerPage = ITEMS_PER_PAGE.large;
      } else if (window.innerWidth >= 1024) {
        newItemsPerPage = ITEMS_PER_PAGE.medium;
      } else if (window.innerWidth >= 768) {
        newItemsPerPage = ITEMS_PER_PAGE.small;
      } else {
        newItemsPerPage = ITEMS_PER_PAGE.extraSmall;
      }
      setItemsPerPage((prev) => {
        if (prev !== newItemsPerPage) {
          setCurrentPage(0);
        }
        return newItemsPerPage;
      });
    };

    updateItemsPerPage();
    window.addEventListener("resize", updateItemsPerPage);
    return () => window.removeEventListener("resize", updateItemsPerPage);
  }, []);

  const filteredData = useMemo(
    () =>
      serviceData.filter((item) =>
        item.name.toLowerCase().includes(searchInput.toLowerCase())
      ),
    [serviceData, searchInput]
  );
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  useEffect(() => {
    if (currentPage >= totalPages) {
      setCurrentPage(Math.max(0, totalPages - 1));
    }
  }, [filteredData, totalPages, currentPage]);

  // Handle navigation
  const handleNavigation = (direction: "next" | "prev") => {
    setCurrentPage((prev) =>
      direction === "next"
        ? Math.min(prev + 1, totalPages - 1)
        : Math.max(prev - 1, 0)
    );
  };

  // Get current items
  const currentItems = filteredData.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const getButtonStyles = (isDisabled: boolean) =>
    isDisabled
      ? "bg-[#D9D9D9] opacity-50 cursor-not-allowed"
      : "bg-[#F4B337] hover:bg-[#e5a730] cursor-pointer";



  const NavigationButtons = ({ currentPage, totalPages, onNavigate }: any) => (
    <>
      <button
        onClick={() => handleNavigation("prev")}
        disabled={currentPage === 0}
        className={`text-[#0A2640] w-[40px] h-[40px] text-[24px] flex justify-center items-center rounded-full transition-all duration-200 ${getButtonStyles(
          currentPage === 0
        )}`}
        aria-label="Previous page"
      >
        <BiLeftArrowAlt />
      </button>

      <button
        onClick={() => handleNavigation("next")}
        disabled={currentPage >= totalPages - 1}
        className={`text-[#0A2640] w-[40px] h-[40px] text-[24px] flex justify-center items-center rounded-full transition-all duration-200 ${getButtonStyles(
          currentPage >= totalPages - 1
        )}`}
        aria-label="Next page"
      >
        <BiRightArrowAlt />
      </button>
    </>
  );

  return (
    <div className="mt-[46px]">
      <div className="flex items-center justify-between">
        <h1 className="lg:text-[53px] text-[6vw] font-semibold">
          {t("Categories")}
        </h1>
        <div className="flex gap-[15px]">
          <NavigationButtons
            currentPage={currentPage}
            totalPages={totalPages}
            onNavigate={handleNavigation}
          />
        </div>
      </div>

      <div className="grid 4xl:grid-cols-6 lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-16 gap-11 ">
        {currentItems.map((item: any, index: number) => (
          <div
            key={`${item.name}-${index}`}
            onClick={() => searchLabour(item)}
            className="flex flex-col items-center cursor-pointer"
          >
            <div className="relative w-64 h-60 overflow-hidden rounded-lg border border-gray-300 group">
              <img
                src={
                  item?.imageUrl
                    ? getCompleteImageUrl(item.imageUrl)
                    : require("../assets/images/no_preview_image.png")
                }
                alt={item.name}
                className="w-full h-full object-cover object-center transition-transform duration-300 group-hover:scale-110"
                loading="lazy"
                width={512}
                height={480}
              />
              <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-10 transition-opacity duration-300" />
            </div>
            <p className="text-[#272D29] 4xl:text-[1.2vw] text-lg md:text-xl font-medium mt-4 text-center">
              {capitalizeWords(t(item.name))}
            </p>
          </div>
        ))}
      </div>
      <div className="flex justify-end gap-[15px] mt-24 mb-3">
        <NavigationButtons
          currentPage={currentPage}
          totalPages={totalPages}
          onNavigate={handleNavigation}
        />
      </div>
    </div>
  );
};

export default CategoryGrid;
