import * as yup from "yup";
import {
  PHONE_NUMBER_REQUIRED,
  INVALID_PHONE_NUMBER_FORMAT,
} from "../constants/ValidationMessage";

export const PhoneSchema = yup.object().shape({
  phone: yup
    .string()
    .required(PHONE_NUMBER_REQUIRED)
    .matches(/^[0-9]{10}$/, INVALID_PHONE_NUMBER_FORMAT),
});
