import React, { useState, useRef, useCallback } from "react";
import MapComponent from "../../component/GoogleMapComponent";
import { useLocationContext } from "../../component/Context/LocationContext";
import { useGeneralSettings } from "../../API/api";

const LocationSelector: React.FC = () => {
  const [showPopover, setShowPopover] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);
  const [isPlacesAutoCompleteVisible, setIsPlacesAutoCompleteVisible] =
    useState<boolean>(false);

  const { state } = useLocationContext();
  const { data: generalData, isLoading } = useGeneralSettings();
  const GOOGLE_MAPS_API_KEY = generalData?.configurations?.GOOGLE_MAPS_API_KEY;
  const handleTogglePopover = () => {
    setShowPopover(!showPopover);
  };

  const onInteraction = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  // Memoized callback for mouse move event
  const handleClickOutside = useCallback((e: MouseEvent) => {
    if (popoverRef.current && !popoverRef.current.contains(e.target as Node)) {
      setShowPopover(false);
    }
  }, []);

  const checkVisibility = (pacContainer: Element) => {
    if (pacContainer) {
      const styles = window.getComputedStyle(pacContainer);
      const isDisplayNone = styles.display === "none";
      const isVisibilityHidden = styles.visibility === "hidden";
      const isOpacityZero = parseFloat(styles.opacity) === 0;
      const visible = !(isDisplayNone || isVisibilityHidden || isOpacityZero);
      setIsPlacesAutoCompleteVisible(visible);
    }
  };

  React.useEffect(() => {
    //Work around to check whether the google places auto complete is visible or not.
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "childList" || mutation.type === "attributes") {
          const pacContainers = document.querySelectorAll(".pac-container");
          if (pacContainers.length != 0) {
            pacContainers.forEach((container) => checkVisibility(container));
          } else {
            setIsPlacesAutoCompleteVisible(false);
          }
        }
      });
    });

    // Observe changes in the DOM subtree
    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
    });
    return () => {
      observer.disconnect();
    };
  }, []);

  React.useEffect(() => {
    // if places auto complete is visible remove the mousedown listener otherwise , this will
    // steal the event from auto complete and it wont call the on place selected callback.
    if (isPlacesAutoCompleteVisible) {
      document.removeEventListener("mousedown", handleClickOutside, true);
    } else {
      document.addEventListener("mousedown", handleClickOutside, true);
    }
  }, [isPlacesAutoCompleteVisible]);

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  }, []);

  const displayLocation = () => {
    const { selectedLocationData } = state;
    if (selectedLocationData) {
      const parts = [
        selectedLocationData.city,
        selectedLocationData.district,
        selectedLocationData.state,
      ].filter(Boolean);
      return parts.join(", ");
    }
    return "Select Location";
  };

  return (
    <button
      type="button"
      className="border-[#B9B9B9] border-[1px] rounded-md flex gap-3 items-center px-3 w-full min-w-[200px]"
    >
      <div className="relative w-full">
        <div
          onClick={(e) => {
            e.stopPropagation();
            handleTogglePopover();
          }}
          className="flex items-center p-1 rounded cursor-pointer md:p-2 text-start"
        >
          <p className="w-full text-gray-800 text-[15px]">
            {displayLocation()}
          </p>
        </div>

        {showPopover && !isLoading && (
          <MapComponent
            showPopover={showPopover}
            showSearchBar={true}
            popoverRef={popoverRef}
            handleTogglePopover={handleTogglePopover}
            onInteraction={onInteraction}
            GOOGLE_MAPS_API_KEY={GOOGLE_MAPS_API_KEY}
          />
        )}
      </div>
    </button>
  );
};

export default LocationSelector;
