import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BankDetailsDto, useGetBankDetails, useUserMeQuery } from "../API/api";
import {
  useAddBankDetails,
  useUpdateBankDetails,
} from "../component/services/Mutation";
import BankDetailsForm from "../component/BankDetails/BankDetailsForm";
import BankDetailsTable from "../component/BankDetails/BankDetailsTable";
import ConfirmationModal from "../component/DeleteModal";
import { toast } from "react-toastify";

const Settings = () => {
  const { t } = useTranslation();
  // const tabs = [t("Bank Details")];
  // const [activeTab, setActiveTab] = useState(tabs[0]);
  const [activeTab, setActiveTab] = useState(t("Bank Details"));
  const tabs = [t("Bank Details")];
  const [editData, setEditData] = useState<any>(null);
  const [openModal, setOpenModal] = useState(false);
  const { data: userData } = useUserMeQuery();
  const userId = userData?.user?.id;
  const addBankDetailsMutation = useAddBankDetails();
  const updateBankDetailsMutation = useUpdateBankDetails();
  const { data: acountData, isLoading } = useGetBankDetails();
  const [deleteItem, setDeleteItem] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const handleDeleteClick = (item: any) => {
    setDeleteItem(item?.id);
    setShowConfirmDialog(true);
  };
  const handleEdit = (data: BankDetailsDto) => {
    setOpenModal(!openModal);
    setEditData(data);
  };

  const handleAddBank = () => {
    setOpenModal(!openModal);
    setEditData(null);
  };

  const handleCancel = () => {
    setOpenModal(false);
  };

  const handleConfirmDelete = async () => {
    const bankDetails: any = {
      isDeleted: true,
    };
    if (deleteItem !== null) {
      try {
        bankDetails.id = deleteItem;
        await updateBankDetailsMutation.mutateAsync(bankDetails);
        setShowConfirmDialog(false);
      } catch (error) {
        toast.error("Failed to delete bank account. Please try again later.");
        console.error("Failed to delete bank account. Please try again later.");
        setShowConfirmDialog(false);
      }
    }
  };

  const handleCancelDelete = () => {
    setShowConfirmDialog(false);
  };
  return (
    <>
      <div className="mainDiv w-full ">
      <div className="mt-8 md:text-start  ">
        <h1 className="text-[#162634] lg:text-responsive-xl text-responsive-base font-bold ">
          {t("Settings")}
        </h1>
        </div>
        <div className="topListDiv w-full ">
          <div className="flex pt-4 gap-10 ">
            {tabs.map((tab, index) => (
              <button
                key={index}
                className={`text-responsive-base font-medium   ${
                  activeTab === tab ? "border-b-4 border-[#fbe0b1]" : ""
                }`}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </button>
            ))}
          </div>
        </div>
        {activeTab === t("Bank Details") && (
          <>
            {openModal && (
              <div className="fixed top-0 left-0 w-full p-6 h-full bg-black bg-opacity-25 flex justify-center items-center z-50">
                <BankDetailsForm
                  userId={userId}
                  addBankDetailsMutation={addBankDetailsMutation}
                  updateBankDetailsMutation={updateBankDetailsMutation}
                  editData={editData}
                  onCancel={handleCancel}
                />
              </div>
            )}

            <div className=" flex justify-start md:justify-end md:gap-2 md:p-1 py-3 ">
              <button
                type="button"
                onClick={handleAddBank}
                className="px-4 py-2  text-responsive-sm font-semibold text-gray-700 bg-gray-200 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300"
              >
                {t("Add New Bank")}
              </button>
            </div>
            <BankDetailsTable
              acountData={acountData}
              handleEdit={handleEdit}
              handleDeleteClick={handleDeleteClick}
              isLoading={isLoading}
            />
          </>
        )}
      </div>

      <ConfirmationModal
        isOpen={showConfirmDialog}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        isLoading={updateBankDetailsMutation.isLoading}
        itemName={t("Bank Details")}
      />
    </>
  );
};

export default Settings;
