import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/messaging";

if ("serviceWorker" in navigator) {
  const firebaseConfig = {
    apiKey: "AIzaSyD8rCw8IGc1JgTsyWc1bgOol4pX_m7bti0",
    authDomain: "eaidexfirenote.firebaseapp.com",
    projectId: "eaidexfirenote",
    storageBucket: "eaidexfirenote.appspot.com",
    messagingSenderId: "704256427947",
    appId: "1:704256427947:web:462595381c9f20ff1b74ee",
    measurementId: "G-YBPZ9GWB3V",
  };

  /** Production config */
  const firebaseConfigProd = {
    apiKey: "AIzaSyCvWS8kDQW3fKeLJ1t_5D1AwmlvWYCr0zg",
    authDomain: "eaidex-pro.firebaseapp.com",
    projectId: "eaidex-pro",
    storageBucket: "eaidex-pro.firebasestorage.app",
    messagingSenderId: "371578735991",
    appId: "1:371578735991:web:e7755c89ad3de7eb49e572",
    measurementId: "G-FEX4P90XKZ",
  };

  if (process.env.NODE_ENV === "development") {
    firebase?.initializeApp(firebaseConfig);
  } else {
    firebase?.initializeApp(firebaseConfigProd);
  }
}
export default firebase;
