import React, { Fragment, useState, useMemo, useCallback } from "react";
import { Transition, Listbox } from "@headlessui/react";
import {
  UnreadNotificationDto,
  useNotificationCounts,
  useUnreadNotifications,
} from "../../API/api";
import { useMarkAllAsRead } from "../services/Mutation";
import moment from "moment";
import CircularProgressBar from "../CircularProgressBar";
import { NotificationIcon } from "../../assets/images/AllSvg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export enum TabName {
  Chat = "Chat",
  General = "General",
}

export enum EventType {
  CHAT_MESSAGE = 1,
  SUBSCRIPTION_EXPIRY = 2,
  SUBSCRIPTION_PAYMENT = 3,
  JOB_STATUS_UPDATE = 4,
  EMPLOYER_REVIEW = 5,
}

export enum JobAwardStatus {
  REQUESTED = 1,
  REJECTED_BY_LABOURER = 2,
  REJECTED_BY_EMPLOYER = 3,
  ACCEPTED = 4,
  CLOSED = 5,
  COMPLETED = 6,
}

interface TabsProps {
  tabs: string[];
  activeTab: string;
  onTabChange: (tab: string) => void;
  counts: Record<string, number>;
}

interface NotificationPayload {
  senderName: string;
  jobStatus?: JobAwardStatus;
}

const COLORS = ["#DEF1FF", "#FFE7DB", "#F0DCFF", "#E9FFF8", "#FEF7DA"] as const;
const PAGE_SIZE = 10;

const getColorByIndex = (index: number): string =>
  COLORS[index % COLORS.length];

const formatDateTime = (date: string) => ({
  date: moment(date).format("DD/MM/YYYY"),
  time: moment(date).format("h:mm A"),
});

const getJobStatusMessage = (jobStatus: JobAwardStatus): string => {
  switch (Number(jobStatus)) {
    case JobAwardStatus.REQUESTED:
      return "You have received a new job request.";
    case JobAwardStatus.REJECTED_BY_LABOURER:
    case JobAwardStatus.REJECTED_BY_EMPLOYER:
      return "Your job request was rejected.";
    case JobAwardStatus.ACCEPTED:
      return "Your job request was accepted.";
    case JobAwardStatus.CLOSED:
      return "The job has been closed.";
    case JobAwardStatus.COMPLETED:
      return "The job was successfully completed.";
    default:
      return "The job status has been updated.";
  }
};

const Tabs: React.FC<TabsProps> = ({
  tabs,
  activeTab,
  onTabChange,
  counts,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex gap-7 border-b border-gray-200 mt-2">
      {tabs.map((tab) => (
        <button
          key={tab}
          className={`flex gap-2 py-2 text-start ${
            activeTab === tab
              ? "border-b-2 border-blue-600 font-semibold text-sm text-blue-600"
              : "text-black font-medium"
          }`}
          onClick={() => onTabChange(tab)}
        >
          {t(tab)}
          <span className="text-sm bg-[#e5eeff] text-gray-800 font-medium rounded-full px-2 py-1 flex items-center justify-center w-5 h-5">
            {counts[tab]}
          </span>
        </button>
      ))}
    </div>
  );
};

// MAIN COMPONENT
const NotificationBox: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<TabName>(TabName.Chat);
  const [pageNumber, setPageNumber] = useState(1);

  const eventTypes = useMemo(
    () =>
      activeTab === TabName.Chat
        ? [EventType.CHAT_MESSAGE]
        : [
            EventType.SUBSCRIPTION_EXPIRY,
            EventType.SUBSCRIPTION_PAYMENT,
            EventType.JOB_STATUS_UPDATE,
            EventType.EMPLOYER_REVIEW,
          ],
    [activeTab]
  );

  const { data: notificationData } = useNotificationCounts();
  const {
    data: unreadNotificationData = [],
    isLoading: notificationsIsLoading,
    isError: notificationsIsError,
  } = useUnreadNotifications(pageNumber, PAGE_SIZE, eventTypes);

  const { mutate: markAllAsRead, isLoading: isMarkingRead } =
    useMarkAllAsRead();

  const counts = useMemo(
    () => ({
      Chat: notificationData?.[0]?.chatNotifCount ?? 0,
      General: notificationData?.[0]?.generalNotifCount ?? 0,
    }),
    [notificationData]
  );

  const totalNotificationCount = notificationData?.[0]?.total ?? 0;
  const currentCount =
    activeTab === TabName.Chat ? counts.Chat : counts.General;
  const totalPages = Math.ceil(currentCount / PAGE_SIZE);

  const paginationState = useMemo(
    () => ({
      isFirstPage: pageNumber === 1,
      isLastPage: pageNumber >= totalPages,
    }),
    [pageNumber, totalPages]
  );

  const handleMarkAllAsRead = useCallback(() => {
    const ids = unreadNotificationData.map((data) => data?.id).filter(Boolean);
    if (ids.length) markAllAsRead(ids);
  }, [unreadNotificationData, markAllAsRead]);

  const handlePageChange = useCallback(
    (newPage: number) => {
      if (newPage >= 1 && newPage <= totalPages) {
        setPageNumber(newPage);
      }
    },
    [totalPages]
  );

  const handleTabChange = useCallback((tab: string) => {
    setActiveTab(tab as TabName);
    setPageNumber(1);
  }, []);

  const handleNotificationClick = useCallback(
    (type: EventType) => {
      if (type === EventType.CHAT_MESSAGE) {
        navigate("/messageCenter");
      }
    },
    [navigate]
  );

  // MEMOIZE NOTIFICATION MESSAGE GENERATOR
  const getNotificationMessage = useCallback(
    (type: EventType, payload: NotificationPayload): string => {
      switch (type) {
        case EventType.CHAT_MESSAGE:
          return `${t("You have an unread message from")} ${
            payload.senderName
          }.`;
        case EventType.JOB_STATUS_UPDATE:
          return getJobStatusMessage(payload.jobStatus!);
        case EventType.SUBSCRIPTION_EXPIRY:
          return t("Your subscription is expiring soon.");
        case EventType.SUBSCRIPTION_PAYMENT:
          return t("Your subscription payment was successful.");
        case EventType.EMPLOYER_REVIEW:
          return t("An employer has reviewed your work.");
        default:
          return t("You have a new notification.");
      }
    },
    [t]
  );

  const renderNotification = useCallback(
    (notification: UnreadNotificationDto, index: number) => {
      const { type, createdAt, payload } = notification;
      const parsedPayload: NotificationPayload = JSON.parse(payload);
      const { date, time } = formatDateTime(createdAt);
      const backgroundColor = getColorByIndex(index);
      const message = getNotificationMessage(type, parsedPayload);
      const senderName = parsedPayload.senderName || "User";

      return (
        <Listbox.Option
          key={notification.id}
          className={({ active }) =>
            `relative cursor-default select-none py-2 px-1 ${
              active ? "bg-gray-100" : "text-gray-900"
            }`
          }
          value={notification}
          onClick={() => handleNotificationClick(type)}
        >
          <div className="border-b border-gray-200">
            <div className="flex flex-col">
              <div className="flex items-center space-x-4 justify-center p-2">
                <div
                  className="md:w-14 md:h-10 w-14 h-8 flex items-center justify-center rounded-full"
                  style={{ backgroundColor }}
                >
                  <span className="text-sm font-serif font-semibold text-gray-800">
                    {senderName?.slice(0, 2).toUpperCase()}
                  </span>
                </div>
                <div className="flex flex-wrap space-x-1 text-[12px] md:text-sm text-start">
                  <p className="text-sm">{message}</p>
                  <p className="font-semibold">{senderName} </p>
                  <p>{t("on")}</p>
                  <p>{date}</p>
                </div>
              </div>
              <div className="md:text-sm text-[13px] text-gray-400">{time}</div>
            </div>
          </div>
        </Listbox.Option>
      );
    },
    [getNotificationMessage, handleNotificationClick, t]
  );

  return (
    <div className="relative flex items-center justify-center md:gap-4 sm:gap-0">
      <Listbox as="div" className="relative" value={null}>
        {({ open }) => (
          <>
            <Listbox.Button className="relative w-full cursor-pointer flex items-center">
              <NotificationIcon />
              {totalNotificationCount > 0 && (
                <div className="absolute top-0 right-0 inline-flex items-center justify-center w-5 h-5 text-xs font-semibold text-white bg-red-500 rounded-full">
                  {totalNotificationCount}
                </div>
              )}
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute max-h-[476px] right-0 mt-2 w-72 sm:w-96 py-1 overflow-auto text-base bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm translate-x-1/2 sm:right-1/2 lg:translate-x-0 lg:right-0">
                <div className="p-4">
                  <div className="flex justify-between items-center">
                    <h2 className="text-lg font-semibold sm:text-sm">
                      {t("Notifications")}
                    </h2>
                    {unreadNotificationData.length > 0 && (
                      <button
                        className="text-sm font-semibold text-blue-600"
                        onClick={handleMarkAllAsRead}
                        disabled={isMarkingRead}
                      >
                        {isMarkingRead
                          ? t("Marking...")
                          : t("Mark all as read")}
                      </button>
                    )}
                  </div>

                  <Tabs
                    tabs={[TabName.Chat, TabName.General]}
                    activeTab={activeTab}
                    onTabChange={handleTabChange}
                    counts={counts}
                  />
                </div>

                {!paginationState.isFirstPage && (
                  <div className="text-center">
                    <button
                      className="text-sm text-blue-500 hover:underline mr-4"
                      onClick={() => handlePageChange(pageNumber - 1)}
                    >
                      {t("Previous")}
                    </button>
                  </div>
                )}

                {notificationsIsLoading ? (
                  <CircularProgressBar />
                ) : notificationsIsError ? (
                  <p className="text-center text-red-500">
                    {t("Error loading notifications")}
                  </p>
                ) : unreadNotificationData.length === 0 ? (
                  <p className="text-center">
                    {t("No notifications available")}
                  </p>
                ) : (
                  unreadNotificationData.map(renderNotification)
                )}

                {!paginationState.isLastPage && (
                  <div className="px-4 py-2 text-center">
                    <button
                      className="text-sm text-blue-500 hover:underline"
                      onClick={() => handlePageChange(pageNumber + 1)}
                      disabled={notificationsIsLoading}
                    >
                      {t("Load More")}
                    </button>
                  </div>
                )}
              </Listbox.Options>
            </Transition>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default NotificationBox;
