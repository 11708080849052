import React, { useState, useMemo, useCallback } from "react";
import { Controller, Control } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import {
  LabourerSkillAttributeDto,
  useServiceCategoryQuery,
  useSkillAttributes,
} from "../../API/api";
import { ServiceProviderType } from "../LabourerRegisterForm/LabourerRegisterForm";
import { Service, SkillAttributesDataDto } from "../../API/types";

export interface Skill {
  id: number;
  name: string;
  experience: number;
}

interface SkillAttributesResult {
  data: SkillAttributesDataDto | undefined;
  isLoading: boolean;
}

interface MyAutocompleteProps {
  control: Control<any>;
  handleSkillUpdate: (skills: Skill[]) => void;
  error?: string;
  providerType: ServiceProviderType;
  selectedSkills: Skill[];
  setSelectedSkills: any;
  setShowBusinessModal: React.Dispatch<React.SetStateAction<boolean>>;
  setFormattedAttributes: React.Dispatch<React.SetStateAction<any>>;
}

const MyAutocomplete: React.FC<MyAutocompleteProps> = ({
  control,
  handleSkillUpdate,
  error,
  providerType,
  selectedSkills,
  setSelectedSkills,
  setShowBusinessModal,
  setFormattedAttributes,
}) => {
  // State management
  const [dynamicAttributes, setDynamicAttributes] = useState<{
    [key: number]: { [attrId: number]: string };
  }>({});
  const [validationErrors, setValidationErrors] = useState<{
    [key: number]: string;
  }>({});
  const [attributeErrors, setAttributeErrors] = useState<{
    [key: number]: { [attrId: number]: string };
  }>({});
  const [showExperience, setShowExperience] = useState<boolean>(false);

  // Fetch service categories
  const servicesQuery = useServiceCategoryQuery();
  const serviceCategories: Service[] = servicesQuery.data || [];
  // Map Service[] to Skill[] by adding the `experience` property
  const mappedSkills = serviceCategories.map((service) => ({
    id: service.id,
    name: service.name,
    experience: 0, 
  }));
  // Fetch skill attributes based on selected skills
  const skillIds = useMemo(
    () => selectedSkills.map((skill) => skill.id),
    [selectedSkills]
  );
  const { data: skillAttributesData, isLoading: skillIsLoading } =
    useSkillAttributes(skillIds);
  const skillAttributes = skillAttributesData?.skillAttributes ?? [];
  const masterAttributes = skillAttributesData?.masterAttributes ?? [];

  // Handlers
  const handleSkillChange = useCallback(
    (event: any, newValue: Skill[], field: any) => {
      let updatedSkills =
        providerType === ServiceProviderType.Business
          ? newValue.slice(-1)
          : newValue;
      setSelectedSkills(updatedSkills);
      field.onChange(
        updatedSkills.map((skill) => ({
          id: skill.id,
          name: skill.name,
          experience: skill.experience || 0,
        }))
      );

      if (providerType === ServiceProviderType.Individual) {
        setShowExperience(updatedSkills.length > 0);
        setShowBusinessModal(false);
      } else if (providerType === ServiceProviderType.Business) {
        setShowBusinessModal(updatedSkills.length > 0);
        setShowExperience(false);
      }
    },
    [providerType, setSelectedSkills, setShowBusinessModal, setShowExperience]
  );

  const handleAttributeChange = useCallback(
    (skillId: number, attrId: number, value: string) => {
      setDynamicAttributes((prevState) => ({
        ...prevState,
        [skillId]: {
          ...prevState[skillId],
          [attrId]: value,
        },
      }));
    },
    []
  );

  const validateExperience = () => {
    const errors: { [key: number]: string } = {};
    selectedSkills.forEach((skill) => {
      if (!skill.experience || skill.experience <= 0) {
        errors[skill.id] = "Experience must be greater than 0";
      }
    });
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const validateAttributes = () => {
    const errors: { [key: number]: { [attrId: number]: string } } = {};
    selectedSkills.forEach((skill) => {
      skillAttributes
        .filter((attr) => attr.skillId === skill.id && attr.isVisible)
        .forEach((attr) => {
          const attribute = masterAttributes.find(
            (masterAttr) => masterAttr.id === attr.skillMasterAttributeId
          );
          const value =
            dynamicAttributes[skill.id]?.[attr.skillMasterAttributeId] || "";
          if (value.trim() === "" && attribute?.attributeLabel) {
            if (!errors[skill.id]) errors[skill.id] = {};
            errors[skill.id][
              attr.skillMasterAttributeId
            ] = `${attribute.attributeLabel} is required`;
          }
        });
    });
    setAttributeErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSave = () => {
    const isExperienceValid = validateExperience();
    const isAttributesValid = validateAttributes();
    if (isExperienceValid && isAttributesValid) {
      const formattedAttributes = selectedSkills.flatMap((skill) => {
        const attributes = dynamicAttributes[skill.id];
        return attributes
          ? Object.entries(attributes)
              .map(([attrId, attrValue]) => {
                const skillAttribute = skillAttributes.find(
                  (attr) =>
                    attr.skillMasterAttributeId === Number(attrId) &&
                    attr.skillId === skill.id
                );
                return skillAttribute
                  ? {
                      skillAttributeId: skillAttribute.id,
                      skillAttributeValue: attrValue,
                    }
                  : null;
              })
              .filter((attr) => attr !== null)
          : [];
      });
      setFormattedAttributes(formattedAttributes);
      setShowExperience(false);
    } else {
      setShowExperience(true);
    }
  };

  // UI Components
  // Modular function for rendering skill attributes with enhanced UI
  const renderSkillAttributes = (skill: Skill) => {
    if (skillIsLoading) {
      return (
        <div className="flex justify-center items-center h-full">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#F0A61F]"></div>
        </div>
      );
    }

    return skillAttributes
      .filter((attr) => attr.skillId === skill.id && attr.isVisible)
      .map((attr) => {
        const attribute = masterAttributes.find(
          (masterAttr) => masterAttr.id === attr.skillMasterAttributeId
        );

        return (
          attribute && (
            <div key={attr.skillMasterAttributeId} className="mb-2">
              <input
                type="text"
                placeholder={attribute.attributeLabel}
                className="shadow-md bg-white rounded-lg px-4 py-2 w-full focus:outline-none focus:border-[#F0A61F] focus:ring-1 focus:ring-[#F0A61F]"
                value={
                  dynamicAttributes[skill.id]?.[attr.skillMasterAttributeId] ||
                  ""
                }
                onChange={(e) =>
                  handleAttributeChange(
                    skill.id,
                    attr.skillMasterAttributeId,
                    e.target.value
                  )
                }
              />
              {attributeErrors[skill.id]?.[attr.skillMasterAttributeId] && (
                <div className="text-red-600 text-sm mt-1 mb-5 ml-2">
                  {attributeErrors[skill.id][attr.skillMasterAttributeId]}
                </div>
              )}
            </div>
          )
        );
      });
  };

  return (
    <Controller
      name="skills"
      control={control}
      render={({ field }) => (
        <div className="autocomplete-container">
          <Autocomplete
            multiple
            options={mappedSkills}
            value={selectedSkills}
            onChange={(event, newValue) =>
              handleSkillChange(event, newValue, field)
            }
            getOptionLabel={(option: Skill) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option.id}>
                <Checkbox checked={selected} />
                {option.name.charAt(0).toUpperCase() + option.name.slice(1)}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Skills"
                error={!!error}
              />
            )}
          />
          {error && (
            <p className="error-text text-[16px] text-red-600 mt-2">{error}</p>
          )}
          {selectedSkills.length > 0 && showExperience && (
            <div className="absolute top-full mt-2 w-full bg-white p-4 rounded-lg shadow-md border z-10">
              <div className="max-h-[280px] overflow-y-auto no-scrollbar">
                {selectedSkills.map((skill) => (
                  <div
                    key={skill.id}
                    className="skill-form mb-3 p-4 border rounded-lg"
                  >
                    {/* Checkbox with custom styles */}
                    <Checkbox
                      checked
                      sx={{
                        color: "#D4D4D4",
                        "&.Mui-checked": { color: "#F0A61F" },
                        "& .MuiSvgIcon-root": { fontSize: 25 },
                      }}
                      size="small"
                    />
                    <span className="font-semibold text-lg">{skill.name}</span>

                    {/* Experience input */}
                    <div className="flex items-center gap-2 mt-2">
                      <label className="text-base text-gray-600">
                        Experience (Years):
                      </label>
                      <TextField
                        value={skill.experience || ""}
                        onChange={(e) => {
                          const updatedSkills = selectedSkills.map((s) =>
                            s.id === skill.id
                              ? { ...s, experience: Number(e.target.value) }
                              : s
                          );
                          setSelectedSkills(updatedSkills);
                          field.onChange(updatedSkills);
                        }}
                        type="number"
                        error={!!validationErrors[skill.id]}
                        sx={{
                          padding: "0",
                          height: "8px",
                          width: "60px",
                          textAlign: "center",
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#F0A61F",
                            },
                            "& fieldset": {
                              borderColor: "#D4D4D4",
                            },
                            "&:hover fieldset": {
                              borderColor: "#F0A61F",
                            },
                            "&:focus": {
                              outline: "none",
                            },
                            "& input:focus": {
                              outline: "none",
                              boxShadow: "none",
                            },
                            // Hide spinner buttons for Firefox
                            "& input[type=number]": {
                              "-moz-appearance": "textfield",
                            },
                            // Hide spinner buttons for Chrome, Safari, Edge, Opera
                            "& input[type=number]::-webkit-outer-spin-button": {
                              "-webkit-appearance": "none",
                              margin: 0,
                            },
                            "& input[type=number]::-webkit-inner-spin-button": {
                              "-webkit-appearance": "none",
                              margin: 0,
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&:focus": {
                              outline: "none",
                              boxShadow: "none",
                            },
                          },
                        }}
                      />
                    </div>
                    {validationErrors[skill.id] && (
                      <div className="text-red-600 text-xs mt-1 mb-5">
                        {validationErrors[skill.id]}
                      </div>
                    )}

                    {/* Render skill attributes or loading spinner */}
                    {renderSkillAttributes(skill)}
                  </div>
                ))}
              </div>

              <button
                type="button"
                className="bg-[#272D29] text-white font-bold rounded px-4 py-2 mt-4 w-full"
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          )}
        </div>
      )}
    />
  );
};

export default MyAutocomplete;
