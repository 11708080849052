import * as yup from "yup";
import {
  OTP_REQUIRED,
  INVALID_OTP_FORMAT,
} from "../constants/ValidationMessage";

export const OtpSchema = yup.object().shape({
  otp: yup
    .string()
    .required(OTP_REQUIRED)
    .matches(/^[0-9]{6}$/, INVALID_OTP_FORMAT),
});
