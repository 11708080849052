import React from "react";
import { useTranslation } from "react-i18next";
import { AiFillCaretRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../utils/jwtUtils";

const Needjob = ({
  user,
  onRegisterClicked,
}: {
  user: any;
  onRegisterClicked: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <div className="px-[20px] 4xl:mt-0 mt-[64px] md:pt-[64px] pt-1">
      <div className="md:flex block justify-between bg-[#FFFCF6] shadow-box rounded-[20px] 4xl:max-w-[1930px] xl:max-w-[1186px] 2xl:mt-[72px] gap-[46px] 2xl:max-w-[1440px] mx-auto ">
        <div className="2xl:pl-[57px] md:pl-[40px] px-[30px] col-span-2 2xl:py-[65px] md:py-[50px] py-3">
          <h2 className="4xl:text-[2vw] 2xl:text-[48px] lg:text-[40px] text-responsive-2xl md:leading-[55px] leading-1  mb-2.5 font-bold">
            {t("needaJob")}
          </h2>
          <p className="4xl:text-[1.2vw] 2xl:text-[27px] lg:text-[20px] text-[16px] font-medium 2xl:max-w-auto xl:max-w-[600px] max-w-[400px]">
            {t(
              "Looking for job opportunities? Join Eaidex's network and start earning today!"
            )}{" "}
          </p>
          {!isAuthenticated() &&
          // user?.roles &&
          // user?.roles.length > 0 &&
          // user?.roles[0] === "labourer" ? (
          //   <p className="text-[16px] font-bold text-red-600">
          //     Already logged in, Please logout to register new.
          //   </p>
          // ) : (
            <button
              onClick={() => {
                onRegisterClicked();
              }}
              className="flex items-center justify-center 4xl:text-[1vw] text-[15px] leading-[13px] font-medium 4xl:py-[23px] py-[13px] px-[16px] rounded-full bg-[#F0A61F]"
            >
              {t("Signup")}{" "}
              <AiFillCaretRight className="text-[14px] 4xl:text-[1vw]" />
            </button>
          }
        </div>
        <div className="relative">
          <div className="md:absolute bottom-0 2xl:w-max md:w-[300px] w-full right-0 ">
            <img
              src={require("../../assets/images/needjob.png")}
              className="ml-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Needjob;
