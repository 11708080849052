import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from "@material-tailwind/react";

import React, { useRef, useEffect, useState } from "react";
import Webcam from "react-webcam";
import { useUpdateProfileImageMutation } from "../../API/api";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";

interface UploadProfilePicModalProps {
  open: boolean;
  handleClose: () => void;
  handleImageSave: (imageSrc: string) => Promise<void>;
  isApiCalling: boolean;
  isError: boolean;
  error: { statusCode: number; name: string; message: string };
}

export const UploadProfilePic: React.FC<UploadProfilePicModalProps> = ({
  open,
  handleClose,
  handleImageSave,
  isApiCalling,
  isError,
  error,
}) => {
  const webcamRef = useRef<Webcam>(null);
  const mutation = useUpdateProfileImageMutation();
  const { t } = useTranslation();

  const [hasCameraAccess, setHasCameraAccess] = useState(false);
  const [capturedImage, setCapturedImage] = useState<string | null>(null);
  useEffect(() => {
    const requestCameraAccess = async () => {
      try {
        if (open) {
          await navigator.mediaDevices.getUserMedia({ video: true });
          setHasCameraAccess(true);
        }
      } catch {
        setHasCameraAccess(false);
      }
    };
    requestCameraAccess();
    return () => stopCameraStream();
  }, [open]);

  const stopCameraStream = () => {
    if (webcamRef.current && webcamRef.current.stream) {
      webcamRef.current.stream.getTracks().forEach((track) => track.stop());
    }
  };

  const handleCapture = () => {
    if (!webcamRef.current) return;
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
    stopCameraStream();
  };
  const handleSave = async () => {
    if (capturedImage) {
      await handleImageSave(capturedImage);
      stopCameraStream();
    }
  };

  const handleRetake = () => {
    setCapturedImage(null);
    mutation.reset();
  };

  const handleCloseWithCleanup = () => {
    stopCameraStream();
    handleClose();
  };
  const videoConstraints = {
    width: 720,
    height: 360,
    facingMode: "user",
  };
  return (
    <Dialog open={open} handler={handleCloseWithCleanup}>
      <DialogHeader className="flex  items-center justify-between  text-sm relative">
        <span className="flex-1 text-center">
          {t("Change profile picture")}
        </span>
        <button
          onClick={handleClose}
          className="absolute right-1 top-1  text-gray-400 hover:text-gray-700 hover:bg-gray-100 rounded-full p-2 transition-colors duration-300 focus:outline-none"
        >
          <FaTimes className="h-4 w-4 sm:h-5 sm:w-5" />
        </button>
      </DialogHeader>
      <DialogBody divider>
        <div className="w-100 h-[300px] rounded-sm flex justify-center items-center py-4">
          {!(hasCameraAccess || capturedImage) && (
            <p>{t("Allow Camera access to take photo")}</p>
          )}
          {capturedImage && (
            <div className="w-[300px] h-[300px] rounded-full overflow-hidden border border-solid border-gray-500 relative">
              {isApiCalling && (
                <div className="w-[300px] h-[300px] object-cover bg-white bg-opacity-40 flex justify-center items-center absolute top-0 ">
                  {/* Add loading spinner */}
                  <p>Loading</p>
                </div>
              )}
              <img
                src={capturedImage}
                alt="dp"
                className="object-cover h-full"
              />
            </div>
          )}
          {hasCameraAccess && !capturedImage && (
            <div className="w-[300px] h-[300px] rounded-full overflow-hidden border border-solid border-gray-500">
              <Webcam
                audio={false}
                width={300}
                height={300}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                className="object-cover h-full"
              />
            </div>
          )}
        </div>
        {isError && (
          <p className="mt-1 text-sm text-center text-red-500">
            {error.message}
          </p>
        )}
      </DialogBody>
      <DialogFooter>
        {!capturedImage && (
          <Button
            className="bg-[#272D29] rounded-sm normal-case"
            onClick={handleCapture}
          >
            {t("Capture")}
          </Button>
        )}

        {capturedImage && (
          <>
            <Button
              variant="text"
              className="mr-2 text-gray-900 normal-case rounded-sm"
              onClick={handleRetake}
            >
              {t("Capture again")}
            </Button>
            <Button
              className="bg-[#272D29] rounded-sm normal-case"
              onClick={handleSave}
            >
              {t("Save")}
            </Button>
          </>
        )}
      </DialogFooter>
    </Dialog>
  );
};
