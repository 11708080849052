import React, { useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { FaTimes, FaPhoneAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useOtpInput from "../../hooks/useOtpInput";
import { RiLoader2Fill } from "react-icons/ri";

interface OtpFormProps {
  handleOtpSubmit: (data: { otp: string }) => void;
  handleClose: () => void;
  isOpen: boolean;
  isLoading: boolean;
  otpErrors: any;
  setValue: any;
}

export const OtpForm: React.FC<OtpFormProps> = ({
  handleOtpSubmit,
  handleClose,
  isOpen,
  isLoading,
  otpErrors,
  setValue,
}) => {
  const { t } = useTranslation();
  const otpLength = 6;
  useEffect(() => {
    if (isOpen) inputRefs.current[0]?.focus();
  }, [isOpen]);

  const verifyOtp = () => {
    if (otp.every((digit) => digit !== "")) {
      const otpValue = otp.join("");
      handleOtpSubmit({ otp: otpValue });
    } else {
      toast.error(t("Please fill all the OTP digits."));
    }
  };

  // OTP CUSTOM HOOK
  const { otp, handleOtpChange, handleKeyDown, inputRefs } = useOtpInput(
    otpLength,
    verifyOtp,
    setValue
  );

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    verifyOtp();
  };

  return (
    <Dialog open={isOpen} onClose={() => {}} as="div" className="relative z-50">
      <div
        className="fixed inset-0 bg-black/50 backdrop-blur-sm"
        aria-hidden="true"
      />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <form onSubmit={handleSubmit} className="w-full max-w-md relative">
          <Dialog.Panel className="w-full rounded-xl bg-white shadow-xl p-6 sm:p-8">
            <button
              onClick={handleClose}
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-700 hover:bg-gray-100 rounded-full p-2 transition-colors duration-300 focus:outline-none"
            >
              <FaTimes className="h-5 w-5 sm:h-6 sm:w-6" />
            </button>

            <Dialog.Title className="flex items-center gap-3 text-lg font-semibold text-gray-900 mb-6">
              <FaPhoneAlt className="h-6 w-6 text-[#F0A61F]" />
              {t("Verify OTP")}
            </Dialog.Title>

            <div className="grid grid-cols-6 gap-2 sm:gap-4 mt-6">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el)}
                  value={digit}
                  onChange={(e) => handleOtpChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  type="text"
                  maxLength={1}
                  className="h-12 sm:h-14 text-lg sm:text-xl text-center border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#F0A61F] focus:border-[#F0A61F] transition-all duration-150"
                />
              ))}
            </div>
            {otpErrors.otp && (
              <p className="text-red-600 text-sm mt-2">
                {otpErrors.otp.message}
              </p>
            )}

            <div className="mt-8 flex justify-end">
              <button
                type="submit"
                className={`flex items-center justify-center px-6 py-3 rounded-lg text-white text-center transition-transform duration-200 w-full bg-gradient-to-r from-[#F0A61F] to-[#e69900] hover:scale-105 ${
                  isLoading ? "opacity-70 " : ""
                }`}
                disabled={isLoading}
                aria-busy={isLoading}
                aria-live="polite"
              >
                {isLoading ? (
                  <>
                    <span>{t("verifying...")}</span>
                    <RiLoader2Fill className="ml-2 h-5 w-5 animate-spin" />
                  </>
                ) : (
                  t("Verify")
                )}
              </button>
            </div>
          </Dialog.Panel>
        </form>
      </div>
    </Dialog>
  );
};

export default OtpForm;
