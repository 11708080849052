import { useTranslation } from "react-i18next";
import { useUserMeQuery } from "../API/api";
import Labourer from "../component/JobStatistics/Labourer";
import Employer from "../component/JobStatistics/Employer";
import { useEffect, useState } from "react";
import CircularProgressBar from "../component/CircularProgressBar";
import { useLocation, useNavigate } from "react-router-dom";

enum UserRole {
  Labourer = "labourer",
  Employer = "employer",
}

const JobStatistics = () => {
  const { t } = useTranslation();
  const { data: userData, isLoading } = useUserMeQuery();
  const [activeTab, setActiveTab] = useState<UserRole | null>(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tabParam = params.get("tab");

    if (userData?.user?.roles) {
      const { roles } = userData.user;
      const validRole =
        tabParam && roles.includes(tabParam) ? tabParam : roles[0];
      setActiveTab(validRole as UserRole);
    }
  }, [userData, location]);

  const handleTabClick = (role: UserRole) => {
    setActiveTab(role);
    navigate(`/jobstatistics?tab=${role}`, { replace: true });
  };

  return (
    <div className="">
      <div className="text-center  md:text-start md:text-responsive-xl xl:text-responsive-xl  text-responsive-base text-[#272D29] font-semibold my-8">
        {t("Job Statistics")}
      </div>

      {isLoading ? (
        <div className="mt-8 flex justify-center items-center">
          <CircularProgressBar />
        </div>
      ) : (
        <div>
          <nav className="  flex gap-10 justify-center md:justify-start " aria-label="Tabs">
            {userData?.user?.roles?.map((role: string) => (
              <button
                key={role}
                className={` text-black px-2  hover:text-gray-700 whitespace-nowrap border-b-2 
                     text-responsive-sm  font-medium cursor-pointer 
                    ${activeTab === role ? "border-b-2 border-[#F0A61F]" : ""}
                     md:text-responsive-lg  `}
                onClick={() => handleTabClick(role as UserRole)}
              >
                {role === UserRole.Labourer && <span>{t("Labourer")}</span>}
                {role === UserRole.Employer && <span>{t("Employer")}</span>}
              </button>
            ))}
          </nav>
        </div>
      )}

      {activeTab === UserRole.Labourer && <Labourer />}
      {activeTab === UserRole.Employer && <Employer />}
    </div>
  );
};

export default JobStatistics;
