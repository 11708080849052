import React from "react";
import { Dialog } from "@headlessui/react";
import { FaTimes, FaTools } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { classNames, getSkillName } from "../../utils/utils";
import { Skill } from "../../API/types";
import LocationSelector from "../../pages/Location/LocationSelector";
import { useLocationContext } from "../Context/LocationContext";
import { CheckBadgeIcon } from "@heroicons/react/20/solid";

interface SkillSelectionPopupProps {
  openPopup: boolean;
  setOpenPopup: (open: boolean) => void;
  details: any;
  serviceData: any;
  selectedSkill: any;
  handleSkillCheckboxClick: (skill: any) => void;
  handleModalContinue: () => void;
  isFav: boolean;
}

const SkillSelectionPopup: React.FC<SkillSelectionPopupProps> = ({
  openPopup,
  setOpenPopup,
  details,
  serviceData,
  selectedSkill,
  handleSkillCheckboxClick,
  handleModalContinue,
  isFav,
}) => {
  const { t } = useTranslation();
  const { state } = useLocationContext();
  const { selectedLocationData } = state;

  return (
    <Dialog
      open={openPopup && isFav === true}
      onClose={() => {}}
      className="relative z-50"
    >
      <div className="fixed inset-0 bg-black/60 backdrop-blur-md transition-opacity duration-300" />

      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="w-full max-w-xl rounded-2xl bg-white shadow-2xl transform transition-all duration-300 ease-out">
          <div className="relative p-8">
            {/* CLOSE BUTTON */}
            <button
              onClick={() => setOpenPopup(false)}
              className="absolute top-6 right-6 text-gray-400 hover:text-gray-600 p-2 rounded-full hover:bg-gray-100/80 transition-all duration-200"
            >
              <FaTimes className="h-6 w-6" />
            </button>

            {/* HEADER */}
            <div className="mb-7">
              <h1 className=" flex items-center gap-3 text-responsive-2xl font-bold bg-gradient-to-r from-amber-500 via-orange-500 to-orange-600 bg-clip-text text-transparent tracking-tight">
                <FaTools className="h-6 w-6 sm:h-7 sm:w-7 md:h-8 md:w-8 text-[#F0A61F]" />{" "}
                {t("Skills")}
              </h1>{" "}
              <p className="mt-3 text-gray-600">
                {t("Select your expertise and location to continue")}
              </p>
            </div>

            {/* LOCATION SECTION */}
            <div className="mb-8">
              <label
                htmlFor="new-location"
                className="block text-base font-semibold text-gray-900 mb-3"
              >
                {t("Select Your Location")}
              </label>
              <LocationSelector />
            </div>

            {/* SKILL SECTION */}
            <div>
              <h2 className="text-base font-semibold text-gray-900 mb-4">
                {t("Select Skills")}
              </h2>
              <div className="space-y-2 max-h-40 overflow-y-auto pr-1">
                {details?.skills.map((skill: Skill, index: number) => {
                  const isSelected = selectedSkill?.skillId === skill?.skillId;
                  return (
                    <div
                      key={index}
                      onClick={() => handleSkillCheckboxClick(skill)}
                      className={classNames(
                        "group flex items-center gap-3 p-3 rounded-lg border transition-all duration-200 cursor-pointer hover:border-amber-500",
                        isSelected
                          ? "border-amber-500 bg-amber-50"
                          : "border-gray-200"
                      )}
                    >
                      <div
                        className={classNames(
                          "w-5 h-5 rounded flex items-center justify-center transition-colors duration-200",
                          isSelected
                            ? "bg-amber-500"
                            : "border-2 border-gray-300 group-hover:border-amber-500"
                        )}
                      >
                        {isSelected && (
                          <CheckBadgeIcon className="w-3 h-3 text-white" />
                        )}
                      </div>
                      <span
                        className={classNames(
                          "text-sm font-medium",
                          isSelected ? "text-amber-900" : "text-gray-700"
                        )}
                      >
                        {t(getSkillName(skill?.skillId, serviceData))}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>

            {/* CONTINUE BUTTON  */}
            <div className="mt-8 flex justify-center text-center items-center">
              <button
                className={classNames(
                  "w-full py-3 px-4 rounded-lg text-sm font-semibold transition-all duration-200",
                  !selectedSkill ||
                    !selectedLocationData?.lat ||
                    !selectedLocationData?.lng
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-gradient-to-r from-amber-500 to-orange-600 text-white hover:shadow-lg hover:from-amber-600 hover:to-orange-700 transform hover:-translate-y-0.5"
                )}
                disabled={
                  !selectedSkill ||
                  !selectedLocationData?.lat ||
                  !selectedLocationData?.lng
                }
                onClick={handleModalContinue}
              >
                {t("Continue")}
              </button>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default SkillSelectionPopup;
