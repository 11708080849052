export const GET_FAVOURITES = "favourites";
export const GET_FAVOURITES_BY = "favouritesBy";
export const GET_FEEDBACKS = "feedbacks";
export const GET_LANGUAGES = "languages";
export const GET_SERVICE_CATEGORY = "serviceCategory";
export const GET_USERS = "users";
export const GET_CHATS = "chats";
export const GET_CHAT_MESSAGES = "chatMessages";
export const GET_LABOURER_DETAILS = "labourerDetails";
export const GET_JOB_AWARDS = "jobAwards";
export const GET_SEARCH_LABOURER = "searchLabourer";
export const GET_AVAILABILITY = "availability";
export const GET_LABOURER_DATA = "labourerData";
export const GET_JOB_STATISTICS = "jobstatistics";
export const GET_CHATLIST = "chatList";
export const GET_SKILL_ATTRIBUTES = "skillAttributes";
export const GET_PAYMENT = "payment";
export const SEARCH_HISTORY_DATA = "searchHistoryData";
export const CHAT_COUNT = "chatCount";
export const CHAT_COUNTLIST = "chatCountList";
export const REFERRAL_STATUS = "referralStatus";
export const GET_FAQS = "getFaqs";
export const GET_BANK_DETAILS = "getBankDetails";
export const GET_MONTHLY_PROFILE_REVIEWS = "getMonthlyProfileViews";
export const GET_TOTAL_PROFILE_REVIEWS = "getTotalProfileViews";
export const GET_OVERALL_RATING = "getOverallRatingss";
export const GET_LABOURER_SKILL_RATING = "getlabourerSkillRatings";
export const GET_PAYMENT_HISTORY = "getPaymentHistory";
export const GET_REFERRAL_INCENTIVE_LEGENDS = "getReferralIncentiveLegends";
export const GET_MONTHLY_REFERRAL_INCENTIVES = "getMonthlyReferralIncentives";
export const GET_PAYIN_HISTORY = "getPayInHistory";
export const GET_PAYOUT_HISTORY = "getPayOutHistory";
export const GET_SUBSCRIPTION_DETAILS = "getSubscriptionDetails";
export const GET_NOTIFICATION_COUNTS = "getNotificationCounts";
export const GET_UNREAD_NOTIFICATIONS = "getUnreadNotifications";
export const GET_EMPLOYER_REVIEW = "getEmployerReview";
export const GET_GENERAL_SETTINGS = "getGeneralSettings" as const;
export const GET_LABOURER_LEGENDS = "getLabourerlegends";
export const GET_SUBSCRIPTION_DATA = "getSubscriptionData";
export const GET_REVIEWS_DATA = "getReviewsData";


