import * as yup from "yup";
import {
  FIRST_NAME_REQUIRED,
  LAST_NAME_REQUIRED,
  PHONE_REQUIRED,
  EMAIL_REQUIRED,
} from "../constants/ValidationMessage";

let EMAIL_REGX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
let PHONE_REGX = /^[0-9]{10}$/;

export const CheckoutFormSchema = yup
  .object({
    firstName: yup.string().required(FIRST_NAME_REQUIRED).nullable(),
    lastName: yup.string().required(LAST_NAME_REQUIRED).nullable(),

    Email: yup
      .string()
      .required(EMAIL_REQUIRED)
      .matches(EMAIL_REGX, "Invalid email address"),

    Phone: yup
      .string()
      .required(PHONE_REQUIRED)
      .matches(PHONE_REGX, "Mobile number must be exactly 10 digits"),
  })
  .required();
