import React, { useState, ChangeEvent, FormEvent, useRef } from "react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import ReCaptcha from "../component/ReCAPTCHA/ReCAPTCHA";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReportAbuseSchema } from "../schema/reportAbuseSchema";
import { stringify } from "querystring";

interface FormData {
  fullName: string;
  lastName: string;
  email: string;
  webUrl: string;
  serviceSector: string;
  typeOfAbuse: string;
  comments: string;
  attachment: File | null;
  // recaptcha: string;
}

interface FormErrors {
  [key: string]: string;
}

const ReportAbuse: React.FC = () => {
  const { t } = useTranslation();
  const placeholderFirstName = t("Enter Full Name") || "";
  const placeholderLastName = t("lastName") || "";
  const placeholderWeb = t("Web URL with Abuse") || "";
  const placeholderComment = t("commentLimit") || "";

  const placeholderService = t("Service Sector Or Menu Item") || "";
  const placeholderPhone = t("Phone Number") || "";

  const placeholderEmail = t("Email Id") || "";
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isReCaptchaVerified, setIsReCaptchaVerified] = useState(false);
  const [captchaError, setCaptchaError] = useState<string>("");
  const [hide, setHide] = useState<boolean>(false);
  const reCaptchaRef = useRef<any | null>(null);

  const toggleFormVisibility = () => {
    setIsFormVisible((prev) => !prev);
  };

  const handleReCaptchaVerify = (token: string | null) => {
    if (token) {
      setIsReCaptchaVerified(true);
      setCaptchaError("");
    } else {
      setIsReCaptchaVerified(false);
    }
  };
  const onSubmit = async (data: any) => {
    console.log("submit data", data);

    if (isReCaptchaVerified) {
    } else {
      setCaptchaError("Please verify that you are not a robot.");
    }
  };

  const {
    register: reportAbuseForm,
    handleSubmit: reportAbuseSubmission,
    formState: { errors: reportAbuseFormErrors },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(ReportAbuseSchema),
    mode: "onChange",
  });
  const messageValue = watch("comments", "");

  return (
    <div className="px-5">
      <div className="md:grid-cols-2 xl:gap-[67px] gap-[30px] xl:max-w-[1186px] 2xl:mt-[-28px] 2xl:max-w-[1440px] mx-auto 4xl:max-w-[1930px]">
        <h1 className="mx-8 font-bold text-center lg:text-4xl mb-7 lg:text-left mt-14">
          {t("Reporting Abuse")}
        </h1>
        <div className="flex flex-col">
          <div className="flex flex-col justify-center bg-gray-100 min-h-fit lg:flex-row">
            <div className="p-8 lg:w-1/2">
              <p className="mb-4 2xl:text-[20px] lg:text-[10px] text-[12px] text-black lg:mx-0 mx-auto font-sm lg:text-justify">
                {t("Reporting Abuse Para1")}
              </p>
              <p className="mb-4 2xl:text-[20px] lg:text-[10px] text-[12px] text-black lg:mx-0 mx-auto font-sm lg:text-justify">
                {t("Reporting Abuse Para2")}
              </p>
              <p className="2xl:text-[20px] lg:text-[10px] text-[12px] text-black lg:mx-0 mx-auto font-sm lg:text-justify">
                {t("Reporting Abuse Para3")}
              </p>
            </div>
            <div className="flex items-center justify-center order-first w-full lg:w-1/2 md:order-last lg:order-last">
              <img
                src={require("../assets/images/reportabuse-modified.png")}
                className="w-3/4 h-auto "
                alt="Report Abuse Image"
              />
            </div>
          </div>
          <div className="flex gap-1 mx-8 mt-12 mb-5">
            <h1 className="font-semibold lg:text-2xl ">
              {t("Submit An Abuse Report")}
            </h1>
            <button
              onClick={toggleFormVisibility}
              className="text-blue-500 cursor-pointer focus:outline-none"
            >
              <span className="flex items-center ml-5 text-blue-500 sm:mr-10 h-7">
                {isFormVisible ? (
                  <MinusSmallIcon
                    className="w-8 h-8 text-blue-700 "
                    aria-hidden="true"
                  />
                ) : (
                  <PlusSmallIcon
                    className="w-8 h-8 text-blue-700 "
                    aria-hidden="true"
                  />
                )}
              </span>
            </button>
          </div>
          {/* Form */}
          {isFormVisible && (
            <div className="p-8 mt-4 mb-4 border border-gray-200 rounded-xl">
              <form onSubmit={reportAbuseSubmission(onSubmit)}>
                {/* Full Name */}
                <div className="mb-6">
                  <input
                    {...reportAbuseForm("fullName")}
                    type="text"
                    placeholder={placeholderFirstName}
                    id="fullName"
                    name="fullName"
                    className="w-full p-2 bg-gray-50 border border-gray-300 rounded"
                  />
                  <p className="text-[16px] text-red-600">
                    {reportAbuseFormErrors.fullName?.message}
                  </p>
                </div>

                {/* Email */}
                <div className="mb-6">
                  <input
                    {...reportAbuseForm("email")}
                    placeholder={placeholderEmail}
                    type="email"
                    id="email"
                    name="email"
                    className="w-full p-2 bg-gray-50 border border-gray-300 rounded"
                  />
                  <p className="text-[16px] text-red-600">
                    {reportAbuseFormErrors.email?.message}
                  </p>
                </div>
                {/* Phone */}

                <div className="mb-6">
                  <input
                    {...reportAbuseForm("phone")}
                    type="text"
                    id="phone-input"
                    aria-describedby="helper-text-explanation"
                    className="bg-gray-50 border border-gray-300 rounded text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder={placeholderPhone}
                  />
                  <p className="text-[16px] text-red-600">
                    {reportAbuseFormErrors.phone?.message}
                  </p>
                </div>
                {/* Web URL with Abuse */}
                <div className="mb-6">
                  <input
                    placeholder={placeholderWeb}
                    type="text"
                    {...reportAbuseForm("webUrl")}
                    id="webUrl"
                    name="webUrl"
                    className="w-full p-2 border bg-gray-50 border-gray-300 rounded"
                  />
                  <p className="text-[16px] text-red-600">
                    {reportAbuseFormErrors.webUrl?.message}
                  </p>
                </div>
                {/* Service Sector Or Menu Item */}
                <div className="mb-6">
                  <input
                    placeholder={placeholderService}
                    type="text"
                    {...reportAbuseForm("serviceSector")}
                    id="serviceSector"
                    name="serviceSector"
                    className="w-full p-2 border bg-gray-50 border-gray-300 rounded"
                  />
                  <p className="text-[16px] text-red-600">
                    {reportAbuseFormErrors.serviceSector?.message}
                  </p>
                </div>
                {/* Type of Abuse */}
                <div className="mb-6">
                  <select
                    {...reportAbuseForm("typeOfAbuse")}
                    id="typeOfAbuse"
                    name="typeOfAbuse"
                    className="w-full p-2 bg-gray-50 border border-gray-300 rounded"
                  >
                    <option value="" disabled selected>
                      {t("Select Type of Abuse")}
                    </option>

                    <option value="Copyright Infringement">
                      {t("Copyright Infringement")}
                    </option>
                    <option value="Adult Content or CSAM">
                      {t("Adult Content or CSAM")}
                    </option>
                    <option value="Domain Protected by Privacy">
                      {t("Domain Protected by Privacy")}
                    </option>
                    <option value="Fraudulent Business Scheme">
                      {t("Fraudulent Business Scheme")}
                    </option>
                    <option value="Violent or Offensive Content">
                      {t("Inappropriate, Violent or Offensive Content")}
                    </option>
                    <option value="Inaccurate WHOIS information">
                      {t("Invalid, Inaccurate WHOIS information")}
                    </option>
                    <option value="Malware"> {t("Malware")}</option>
                    <option value="Fishing">{t("Fishing")} </option>
                    <option value="Spam"> {t("Spam")}</option>
                    <option value="Trademark Infringement">
                      {t("Trademark Infringement")}
                    </option>
                    {t(
                      " Invalid, Inaccurate WHOIS information, Malware, Fishing, Spam, Trademark Infringement "
                    )}
                  </select>
                  <p className="text-[16px] text-red-600">
                    {reportAbuseFormErrors.typeOfAbuse?.message}
                  </p>
                </div>

                <div className="mb-3">
                  <textarea
                    {...reportAbuseForm("comments")}
                    name="comments"
                    placeholder={placeholderComment}
                    maxLength={600}
                    className="border-[#D1D0CA] border-[1px] rounded mt-[20px] w-full h-[148px] p-3  focus:outline-none"
                  />
                  <div className="flex justify-between items-center ">
                    <p className="text-[16px] text-red-600">
                      {reportAbuseFormErrors.comments?.message}
                    </p>
                    <div className="text-sm text-gray-500">
                      Characters left: {600 - messageValue.length}
                    </div>
                  </div>
                </div>
                {/* Attach File */}
                {hide && (
                  <div className="mb-8">
                    <input
                      type="file"
                      id="attachment"
                      name="attachment"
                      accept=".jpg, .pdf, .doc"
                      className="w-full border border-gray-300 rounded"
                    />

                    <p className="mt-1 text-sm text-gray-500">
                      {t("AttachedFileMaxSize")}
                    </p>
                  </div>
                )}
                <div className=" ">
                  <ReCaptcha
                    ref={reCaptchaRef}
                    onVerify={handleReCaptchaVerify}
                  />
                </div>
                {<p className="text-red-600 text-[16px]">{captchaError}</p>}

                <div className="flex items-center justify-center mb-6 text-center">
                  <button
                    type="submit"
                    className="bg-[#1F2937]  hover:bg-[#2f3e53]  text-white py-2 px-4 rounded mt-10"
                  >
                    {t("REPORT ABUSE")}
                  </button>
                </div>
              </form>
            </div>
          )}
          <div className="h-5"></div>
        </div>
      </div>
    </div>
  );
};

export default ReportAbuse;
