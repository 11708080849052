import { useState, useRef } from "react";
const useOtpInput = (
  otpLength: number,
  verifyOtp: () => void,
  setValue: any
) => {
  const [otp, setOtp] = useState<string[]>(Array(otpLength).fill(""));
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleOtpChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.target.value;

    // ONLY ALLOW NUMBERIC INPUT

    if (/^\d?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      
      // UPDATE FULL OTP FOR VALIDATION
      setValue("otp", newOtp.join(""));

      // MOVE FOCUS TO THE NEXT INPUT
      if (value && index < otpLength - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  // HANDLE KEY DOWN EVENTS
  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    switch (e.key) {
      case "Backspace":
        if (!otp[index] && index > 0) {
          e.preventDefault();
          const newOtp = [...otp];
          newOtp[index - 1] = "";
          setOtp(newOtp);
          inputRefs.current[index - 1]?.focus();
        }
        break;
      case "Enter":
        e.preventDefault();
        verifyOtp();
        break;
      case "ArrowRight":
        if (index < inputRefs.current.length - 1) {
          e.preventDefault();
          inputRefs.current[index + 1]?.focus();
        }
        break;
      case "ArrowLeft":
        if (index > 0) {
          e.preventDefault();
          inputRefs.current[index - 1]?.focus();
        }
        break;
    }
  };

  return {
    otp,
    setOtp,
    handleOtpChange,
    handleKeyDown,
    inputRefs,
  };
};

export default useOtpInput;
