import { REACT_APP_API_URL, apiEndpoint } from "./api";

export interface InitCheckout {
  buyerEmail: string;
  buyerPhone: string;
  buyerFirstName: string;
  buyerLastName: string;
  buyerAddress?: string;
  buyerCity?: string;
  buyerState?: string;
  buyerCountry?: string;
  buyerPinCode?: string;
  userId: number;
  subscriptionPlanId: number;
  userUuid: string;
  subscriptionId?: number;
  paymentUuid: string;
}

export interface InitCheckoutResponse {
  privateKey: string;
  mercid: string;
  chmod: string;
  checksum: string;
  currency: string;
  isocurrency: string;
  UID: string;
  buyerEmail: string;
  buyerPhone: string;
  buyerFirstName: string;
  buyerLastName: string;
  buyerAddress: string;
  buyerCity: string;
  buyerState: string;
  buyerCountry: string;
  buyerPinCode: string;
  userId: number;
  subscriptionPlanId: number;
  userUuid: string;
  paymentId: number;
  paymentUUID: string;
  amountToBePaid: number;
  amountFromWallet: number;
}

export interface InitPaymentRequest {
  subscriptionPlanId: number;
}

export interface PaymentInitResponse {
  paymentId: number;
  paymentUUID: string;
  amountToBePaid: number;
  amountFromWallet: number;
  subscriptionId: number;
}
export interface SubscriptionDto {
  subscriptionPlanId: number;
  userId: number;
  userUuid: string;
  subscriptionId: number;
  paymentUUID: string;
}
export const paymentInit = async (initCheckout: InitCheckout) => {
  return apiEndpoint<InitCheckoutResponse>(
    `${REACT_APP_API_URL}/subscription-payment/init-checkout`,
    initCheckout,
    "POST"
  );
};

export const initPayment = async (subscriptionPlanId: number) => {
  const request: InitPaymentRequest = {
    subscriptionPlanId: subscriptionPlanId,
  };
  return apiEndpoint<PaymentInitResponse>(
    `${REACT_APP_API_URL}/subscription-payment/init`,
    request,
    "POST"
  );
};

export const proceedPaymentUsingPg = async (initCheckout: InitCheckout) => {
  return apiEndpoint<InitCheckoutResponse>(
    `${REACT_APP_API_URL}/subscription-payment/complete-using-pg`,
    initCheckout,
    "POST"
  );
};
type UseWalletDto = { paymentUuid: string; subscriptionId: number };
export const proceedPaymentUsingWallet = async (useWallet: UseWalletDto) => {
  return apiEndpoint<InitCheckoutResponse>(
    `${REACT_APP_API_URL}/subscription-payment/complete-using-wallet`,
    useWallet,
    "POST"
  );
};
