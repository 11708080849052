import React, { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  XMarkIcon,
  AdjustmentsHorizontalIcon,
  LanguageIcon,
  WrenchIcon,
} from "@heroicons/react/24/outline";
import { Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";

interface ServiceDto {
  id: number;
  name: string;
  serviceCategoryId: number;
}
interface LanguageDto {
  id: number;
  name: string;
}

const FilterModal = (props: any) => {
  const { t } = useTranslation();
  const [tempSelectedLanguages, setTempSelectedLanguages] = useState(
    props.selectedLanguages
  );
  const [tempSelectedSkill, setTempSelectedSkill] = useState(
    props.selectedSkill
  );
  const [tempSelectedSortOption, setTempSelectedSortOption] = useState(
    props.selectedSortOption
  );
  const selectedServiceId = props.SelectedService?.id;
  const handleLanguageToggle = (languageId: number) => {
    if (tempSelectedLanguages.includes(languageId)) {
      setTempSelectedLanguages(
        tempSelectedLanguages.filter((id: number) => id !== languageId)
      );
    } else {
      setTempSelectedLanguages([...tempSelectedLanguages, languageId]);
    }
  };

  const handleSkillToggle = (skillId: number) => {
    if (skillId === selectedServiceId) return;

    if (tempSelectedSkill.includes(skillId)) {
      setTempSelectedSkill(
        tempSelectedSkill.filter((id: number) => id !== skillId)
      );
    } else {
      setTempSelectedSkill([...tempSelectedSkill, skillId]);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setTempSelectedSortOption(event.target.value);
  };

  const handleDone = () => {
    props.setOpenFilter(false);
    props.setSelectedLanguages(tempSelectedLanguages);
    props.setSelectedSkill(tempSelectedSkill);
    props.setSelectedSortOption(tempSelectedSortOption);
    props.setOpenFilter(false);
  };
  return (
    <Transition show={props?.openFilter} as={React.Fragment}>
      <Dialog
        onClose={() => props?.setOpenFilter(false)}
        className="relative z-50"
      >
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="md:w-[600px]   transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 flex items-center justify-between"
                >
                  <span className="flex items-center">
                    <AdjustmentsHorizontalIcon className="h-5 w-5 mr-2 text-yellow-600" />
                    {t("Filter Options")}
                  </span>
                  <button
                    onClick={() => props?.setOpenFilter(false)}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <XMarkIcon className="h-6 w-6" />
                  </button>
                </Dialog.Title>

                <div className="mt-4 space-y-4">
                  <div>
                    <label
                      htmlFor="sort"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("Sort by")}
                    </label>

                    <select
                      id="sort"
                      className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base sm:text-sm focus:ring-yellow-400 focus:border-yellow-400"
                      value={tempSelectedSortOption}
                      onChange={handleChange}
                    >
                      <option value="">{t("Select an option")}</option>
                      <option value="distance ASC">{t("Nearest First")}</option>
                      <option value="averageStarRating DESC">
                        {t("Highest Rated")}
                      </option>
                    </select>
                  </div>

                  <div>
                    <h4 className="text-base font-semibold text-gray-700 flex items-center">
                      <LanguageIcon className="h-5 w-5 mr-2 text-yellow-600" />
                      {t("Languages")}
                    </h4>
                    <div className="mt-2 max-h-40 overflow-y-auto">
                      {props.languages?.map((lang: LanguageDto) => (
                        <label
                          key={lang?.id}
                          className="flex items-center justify-between px-4  hover:bg-gray-100 cursor-pointer"
                        >
                          <span className="text-sm font-medium text-gray-700 ">
                            {t(lang?.name)}
                          </span>
                          <Checkbox
                            checked={tempSelectedLanguages.includes(lang?.id)}
                            onChange={() => handleLanguageToggle(lang?.id)}
                            sx={{
                              color: "#D4D4D4",
                              "&.Mui-checked": {
                                color: "#F0A61F",
                              },
                            }}
                          />
                        </label>
                      ))}
                    </div>
                  </div>

                  <div>
                    <h4 className="text-base font-semibold text-gray-700 flex items-center">
                      <WrenchIcon className="h-5 w-5 mr-2 text-yellow-600" />
                      {t("Services")}
                    </h4>
                    <div className="mt-2 max-h-40 overflow-y-auto">
                      {props?.serviceData?.map((service: ServiceDto) => (
                        <label
                          key={service.id}
                          className="flex items-center justify-between px-4  hover:bg-gray-100 cursor-pointer"
                        >
                          <span className="text-sm font-medium text-gray-700 capitalize">
                            {t(service.name)}
                          </span>
                          <Checkbox
                            checked={
                              service.id === selectedServiceId ||
                              tempSelectedSkill.includes(service.id)
                            }
                            onChange={() => handleSkillToggle(service.id)}
                            disabled={service.id === selectedServiceId}
                            sx={{
                              color:
                                service.id === selectedServiceId
                                  ? "#F0A61F"
                                  : "#D4D4D4",
                              "&.Mui-checked": {
                                color: "#F0A61F",
                              },
                            }}
                          />
                        </label>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="mt-6">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent bg-yellow-400 px-4 py-2 text-sm font-medium text-white hover:bg-yellow-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-yellow-500 focus-visible:ring-offset-2"
                    onClick={handleDone}
                  >
                    {t("Apply Filters")}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default FilterModal;
