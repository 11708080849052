import React, { useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa";
import { useLocationContext } from "./Context/LocationContext";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Token } from "@mui/icons-material";
import { FiMapPin, FiSearch } from "react-icons/fi";

interface MapComponentProps {
  showPopover: boolean;
  showSearchBar: boolean;
  popoverRef: React.RefObject<HTMLDivElement>;
  handleTogglePopover: () => void;
  onInteraction: (e: React.MouseEvent) => void;
  GOOGLE_MAPS_API_KEY: any;
}

const MapComponent: React.FC<MapComponentProps> = ({
  showPopover,
  popoverRef,
  handleTogglePopover,
  showSearchBar,
  onInteraction,
  GOOGLE_MAPS_API_KEY,
}) => {
  const { dispatch } = useLocationContext();
  const [inputValue, setInputValue] = useState("");
  const [showPredictions, setShowPredictions] = useState(false);
  const { placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: GOOGLE_MAPS_API_KEY,
    debounce: 300,
  });

  // HANDLE SELECTED PLACE FROM AUTOCOMPLETE PREDICTIONS
  const handlePlaceSelect = (
    place: google.maps.places.AutocompletePrediction
  ) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ placeId: place.place_id }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK && results && results[0]) {
        processGeocoderResult(results[0]);
      }
    });
  };

  // PROCESS GEOCODER RESULTS
  const processGeocoderResult = (result: google.maps.GeocoderResult) => {
    if (result.geometry) {
      const { lat, lng } = result.geometry.location;
      const locationData = {
        lat: lat(),
        lng: lng(),
        city:
          result.address_components.find((c) => c.types.includes("locality"))
            ?.long_name || "",
        state:
          result.address_components.find((c) =>
            c.types.includes("administrative_area_level_1")
          )?.long_name || "",
        district:
          result.address_components.find((c) => c.types.includes("administrative_area_level_3"))
            ?.long_name || "",
      };

      dispatch({ type: "SET_LOCATION", payload: locationData });
      handleTogglePopover();
    }
  };

  //HANDLE GPS DETECTION AND GEOCODE LOCATION
  const handleDetectLocation = () => {
    navigator.geolocation?.getCurrentPosition(
      (position) => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode(
          {
            location: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          },
          (results, status) => {
            if (
              status === google.maps.GeocoderStatus.OK &&
              results &&
              results[0]
            ) {
              processGeocoderResult(results[0]);
            }
          }
        );
      },
      (error) => console.error("Error detecting location:", error)
    );
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    if (value.length > 2) {
      getPlacePredictions({
        input: value,
        componentRestrictions: { country: "IN" }, 
      });

      setShowPredictions(true);
    } else {
      setShowPredictions(false);
    }
  };

  useEffect(() => {
    return () => {
      document
        .querySelectorAll(".pac-container")
        .forEach((container) => container.remove());
    };
  }, []);

  return (
    <div onClick={onInteraction}>
      {showPopover && (
        <div
          ref={popoverRef}
          id="popover-location"
          className={`${
            showPopover ? "block" : "hidden"
          } absolute bg-white border border-gray-300 rounded-[12px] mt-[19px] p-4 right-0 w-[280px] md:w-[360px] z-50`}
        >
          <div className="flex items-center justify-between mb-3">
            <div>
              <Token />
            </div>
            <button
              type="button"
              onClick={handleDetectLocation}
              className="md:text-[14px] text-xs hover:text-indigo-700 hover:font-bold "
            >
              Current Location
            </button>
            <button
              type="button"
              onClick={handleDetectLocation}
              className="md:text-[14px] text-xs  text-indigo-700 font-bold flex p-[3px]"
            >
              Detect Using GPS
              <div className="align-middle text-indigo-700 p-[3px]">
                <FaAngleRight />
              </div>
            </button>
          </div>

          {showSearchBar && (
            <div className="relative">
              <div className="relative">
                <FiSearch
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 
                           text-gray-400 w-5 h-5"
                />
                <input
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}
                  placeholder="Enter a location"
                  className="w-full pl-10 pr-4 py-2.5 rounded-lg border border-gray-200 
                           focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 
                           transition-all duration-200 outline-none"
                />
              </div>
              {showPredictions && placePredictions.length > 0 && (
                <div
                  className="absolute w-full mt-2 bg-white rounded-lg shadow-lg 
                              border border-gray-100 overflow-hidden max-h-[300px] 
                              overflow-y-auto"
                >
                  {placePredictions.map((prediction) => (
                    <div
                      key={prediction.place_id}
                      onClick={() => handlePlaceSelect(prediction)}
                      className="flex items-center gap-3 p-3 hover:bg-gray-50 
                               cursor-pointer transition-colors duration-200 
                               border-b border-gray-100 last:border-b-0"
                    >
                      <FiMapPin className="w-4 h-4 text-gray-400 flex-shrink-0" />
                      <p className="text-sm text-gray-700 truncate">
                        {prediction.description}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          <div className="absolute -z-[1]  right-[20px] top-[-7px] rotate-45 bg-white border border-gray-300 w-3 h-3"></div>
        </div>
      )}
    </div>
  );
};

export default MapComponent;
