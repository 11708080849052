import * as yup from "yup";
import {
  NAME_REQUIRED,
  PHONE_REQUIRED,
  EMAIL_REQUIRED,
  MESSAGE_REQUIRED,
  MOBILE_INVALID,
  EMAIL_INVALID,
  NAME_MAX_LENGTH,
  MESSAGE_MAX_LENGTH,NAME_INVALID,MESSAGE_INVALID
} from "../constants/ValidationMessage";

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const MOBILE_REGEX = /^[0-9]{10}$/;
const NAME_REGEX = /^[A-Za-z]+(?:\s[A-Za-z]+)*$/;
const MESSAGE_REGEX = /^(?!\s*$).+/;
export const FeedbackSchema = yup
  .object()
  .shape({
    email: yup
      .string()
      .required(EMAIL_REQUIRED)
      .matches(EMAIL_REGEX, EMAIL_INVALID),

      phone: yup
      .string()
      .required(PHONE_REQUIRED)
      .matches(MOBILE_REGEX, MOBILE_INVALID),

      name: yup
      .string()
      .required(NAME_REQUIRED)
      .matches(NAME_REGEX, NAME_INVALID)
      .max(30, NAME_MAX_LENGTH),

    message: yup
      .string()
      .required(MESSAGE_REQUIRED)
      .matches(MESSAGE_REGEX, MESSAGE_INVALID)
      .max(600, MESSAGE_MAX_LENGTH),
  })
  .required();
