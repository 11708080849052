import React, { useState } from "react";
import { AiOutlineCamera } from "react-icons/ai";
import TextField from "@mui/material/TextField";
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from "@mui/material/Autocomplete";
import { Downarrow, IndianFlag } from "../../assets/images/AllSvg";
import Checkbox from "@mui/material/Checkbox";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  LabourerSkillAttributeDto,
  useLanguagesQuery,
  useUpdateProfileImageMutation,
  useUserMeQuery,
} from "../../API/api";
import MyAutocomplete from "../../component/AutoCompleteSkills/AutoComplete";
import { useLocation, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { UploadProfilePic } from "../../component/Models/UploadProfilePic";
import { LabourerRegisterSchema } from "../../schema/labourerSignInSchema";
import {
  useSignUpLabourerMutation,
  useSignUpEmployerMutation,
  useAddSkillAttributesValue,
} from "../services/Mutation";
import { Skill, UserRole } from "../../API/types";
import LocationSelector from "../../pages/Location/LocationSelector";
import { useLocationContext } from "../Context/LocationContext";
import PlanDisplay from "./PlanDetails";
import ScrollToTopLink from "../ScrollToTopLink/ScrollToTopLink";
import GenericDialog from "../GenericDialog";
import {
  handleImageUpload,
  handlePayment,
  saveSkillAttributes,
  showToast,
} from "../../utils/registrationUtils";

interface LabourerRegisterFormState {
  register?: RegisterData;
  name?: string;
  referralCode?: string;
  labourer?: boolean;
}
interface RegisterData {
  phone: any;
  verificationId: string;
}
interface Language {
  id: number;
  name: string;
}
export enum ServiceProviderType {
  Individual = 1,
  Business = 2,
}
export enum PAYMENT_STATUS {
  PAYMENT_GATEWAY = "payment_gateway",
  WALLET_PAYMENT = "wallet_payment",
}
enum LoadingButton {
  Submit = "submit",
  Skip = "skip",
}
interface LabourRegisterFormProps {
  skillList: any;
  setSkillList: React.Dispatch<React.SetStateAction<any>>;
  handleSkillUpdate: (updatedSkills: any) => void;
}

const LabourRegisterForm = ({
  skillList,
  setSkillList,
  handleSkillUpdate,
}: LabourRegisterFormProps) => {
  const location = useLocation();
  const { refetch: refetchUser } = useUserMeQuery();
  const [showUseWalletConfirmation, setShowUseWalletConfirmation] =
    useState(false);
  const navigate = useNavigate();

  const {
    register: regData,
    referralCode: regRefCode,
    labourer: regLabourer,
  } = location?.state as LabourerRegisterFormState;

  const { state } = useLocationContext();
  const { selectedLocationData } = state;
  const employeraslabourer = regLabourer === false ? true : regLabourer;
  const { t } = useTranslation();
  const languagesQuery = useLanguagesQuery();
  const languagesData = languagesQuery.data || [];
  const [selectedLanguages, setSelectedLanguages] = useState<number[]>([]);
  const [showUploadPicModal, setShowUploadPicModal] = useState<boolean>(false);
  const [imageErrorStatus, setImageErrorStatus] = useState<boolean>(false);
  const [selectedPlanId, setSelectedPlanId] = useState<number | undefined>();
  const [isSkipClicked, setIsSkipClicked] = useState(false);
  const [url, setUrl] = useState("");
  const [selectedSkills, setSelectedSkills] = useState<Skill[]>([]);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState<LoadingButton | null>(
    null
  );
  const [formattedAttributes, setFormattedAttributes] = useState<
    LabourerSkillAttributeDto[]
  >([]);
  const [providerType, setProviderType] = useState<ServiceProviderType>(
    ServiceProviderType.Individual
  );

  const {
    register: registerForm,
    handleSubmit: registerFormSubmission,
    formState: { errors: registerFormErrors },
    reset: registerFormReset,
    control: registerFormControl,
    watch,
    setValue,
    trigger,
  } = useForm({
    resolver: yupResolver(LabourerRegisterSchema),
    context: { isSkipClicked },

    defaultValues: {
      skills: [],
      firstName: "",
      lastName: "",
      phoneNumber: regData?.phone || "",
    },
    mode: "onChange",
  });

  const [showBusinessModal, setShowBusinessModal] = useState<boolean>(false);

  const watchedFirstName = watch("firstName");
  const watchedLastName = watch("lastName");

  const descriptionValue = watch("description", "") || "";

  const handleSelectedLanguagesChange = (
    value: { id: number; name: string }[]
  ) => {
    const selectedLanguageIds = value.map((language) => language.id);
    setSelectedLanguages(selectedLanguageIds);
  };

  const handleFullNameChange =
    (onChange: (value: string) => void) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const fullName = event.target.value;
      onChange(fullName);
      const nameParts = fullName.trim().split(/\s+/);
      const newFirstName = nameParts[0] || "";
      const newLastName = nameParts.slice(1).join(" ") || "";
      setValue("firstName", newFirstName, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue("lastName", newLastName, {
        shouldDirty: true,
        shouldValidate: true,
      });
      trigger(["firstName", "lastName"]);
    };

  // MUTTATION
  const SignUpLabourerMutation = useSignUpLabourerMutation();
  const SignUpEmployerMutation = useSignUpEmployerMutation();
  const ImageMutation = useUpdateProfileImageMutation();
  const SkillAttributeMutation = useAddSkillAttributesValue();
  const capture = async (imageSrc: string) => {
    if (imageSrc) {
      setUrl(imageSrc);
      setShowUploadPicModal(false);
    }
  };

  const handleProfilePicModalOpen = () => {
    setShowUploadPicModal((value) => !value);
  };

  const handleRegister = async (formData: any) => {
    try {
      if (!selectedLocationData?.lat || !selectedLocationData?.lng) {
        showToast("error", t("INVALID_LOCATION"));
        return;
      }

      if (!url) {
        showToast("error", t("IMAGE_UPLOAD_ERROR"));
        return;
      }

      const updatedFormData = {
        ...formData,
        languagesKnown: selectedLanguages,
        userType: UserRole.LABOURER,
        lat: selectedLocationData.lat,
        lng: selectedLocationData.lng,
        location: selectedLocationData.city,
      };

      const mutation = employeraslabourer
        ? SignUpEmployerMutation
        : SignUpLabourerMutation;
      await mutation.mutateAsync(updatedFormData);

      // // doImageUpload function
      // await handleImageUpload(url, setImageErrorStatus, ImageMutation);
      // // ADD SKILL ATTRIBUTES VALUE
      // await saveSkillAttributes(formattedAttributes, SkillAttributeMutation);

      // Handle image upload and skill attributes
      await Promise.all([
        handleImageUpload(url, setImageErrorStatus, ImageMutation),
        saveSkillAttributes(formattedAttributes, SkillAttributeMutation),
      ]);

      if (!isSkipClicked) {
        setIsPaymentLoading(true);
        const result = await refetchUser();
        const userData = result.data;

        if (!userData) {
          throw new Error(String(t("FETCH_USER_FAILED")));
        }
        // HANDLE PAYMENT PROCESS
        const paymentResult = await handlePayment(
          formData,
          userData,
          selectedPlanId,
          setIsPaymentLoading,
          t
        );
        if (paymentResult) {
          navigate("/send-to-paygate", { state: paymentResult, replace: true });
        }
      } else {
        showToast("success", t("REGISTRATION_SUCCESS"));
        navigate("/dashboard");
      }
    } catch (error) {
      console.error("Error during registration process:", error);
      showToast("error", t("UNEXPECTED_ERROR"));
    }
  };

  return (
    <>
      <form onSubmit={registerFormSubmission(handleRegister)}>
        <div className="max-w-[857px] w-full mt-[45px] sm:px-5 px-3 mx-auto p-10   border border-gray-300 shadow-lg rounded ">
          <div className="flex gap-[39px] w-full md:flex-row flex-col">
            <div>
              <div className="bg-[#FBE9C7] min-w-[147px] h-[147px] w-max mx-auto flex justify-center items-center rounded-full">
                <div className="w-[150px] h-[150px] rounded-full overflow-hidden">
                  <img
                    src={url ? url : require("../../assets/images/profile.png")}
                    alt=""
                    className="object-cover h-full"
                  />
                </div>
              </div>
              <button
                type="button"
                className="bg-[#FBE9C7]  py-1.5 px-10 mx-auto mt-3 flex justify-center items-center"
                onClick={handleProfilePicModalOpen}
              >
                <AiOutlineCamera className="text-[#F0A61F] text-[26px]" />
              </button>
              {showUploadPicModal && (
                <UploadProfilePic
                  open={showUploadPicModal}
                  handleClose={handleProfilePicModalOpen}
                  handleImageSave={capture}
                  isApiCalling={ImageMutation.isLoading}
                  isError={ImageMutation.isError}
                  error={
                    ImageMutation.error as {
                      statusCode: number;
                      name: string;
                      message: string;
                    }
                  }
                />
              )}
            </div>

            <div className="w-full flex flex-col gap-[23px]">
              <div className="flex sm:items-center sm:flex-row flex-col sm:gap-[33px] gap-[10px] w-full">
                <label className="text-[17px] font-medium min-w-[167px]">
                  {t("Name")}
                </label>
                <div className="w-full">
                  <Controller
                    name="name"
                    control={registerFormControl}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        onChange={handleFullNameChange(field.onChange)}
                        className="border-[#D1D0CA] border-[1px] rounded h-[48px] px-[12px] w-full focus:outline-none focus:border-[#F0A61F] focus:ring-0 focus:ring-[#F0A61F]"
                        placeholder="Enter your full name"
                      />
                    )}
                  />{" "}
                  <p className="text-[16px] text-red-600">
                    {registerFormErrors.name?.message}
                  </p>
                </div>
              </div>
              <div className="flex sm:flex-row flex-col sm:gap-[33px] gap-[10px] w-full">
                <label className="text-[17px] sm:mt-3 font-medium min-w-[167px]">
                  {t("MobileNumber")}
                </label>
                <div className="flex rounded-md w-full">
                  <div className="flex gap-1 justify-center items-center py-2.5 px-4 text-sm font-medium text-gray-900 border border-gray-300 rounded-l-md bg-gray-200">
                    <IndianFlag />
                    <span>+91</span>
                  </div>
                  <div className="relative w-full">
                    <input
                      type="number"
                      value={regData?.phone || ""}
                      className="border-[#D1D0CA] border-[1px] bg-gray-100 text-gray-900  h-[48px] px-[12px] w-full focus:outline-none rounded-r-md border-l-0 dark:border-l-gray-700  pointer-events-none"
                      placeholder="Enter mobile number"
                      readOnly
                      {...registerForm("phone")}
                    />
                    <p className="text-[16px] text-red-600">
                      {registerFormErrors.phone?.message}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex sm:items-center sm:flex-row flex-col sm:gap-[33px] gap-[10px] w-full select-main">
                  <label className="text-[17px] font-medium min-w-[167px]">
                    {t("Languages Spoken")}
                  </label>

                  <div className="relative w-full  ">
                    {languagesData && (
                      <Controller
                        name="selectedLanguages"
                        control={registerFormControl}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            multiple
                            id="checkboxes-tags-demo"
                            options={languagesData as readonly Language[]}
                            disableCloseOnSelect
                            getOptionLabel={(option: Language) => option.name}
                            onChange={(event, value, reason, details) => {
                              field.onChange(value);
                              handleSelectedLanguagesChange(value);
                            }}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  sx={{
                                    color: "#D4D4D4",
                                    "&.Mui-checked": {
                                      color: "#F0A61F",
                                    },
                                    "& .MuiSvgIcon-root": { fontSize: 25 },
                                  }}
                                  size="small"
                                  checked={selected}
                                />
                                {option.name}
                              </li>
                            )}
                            style={{ width: "100%" }}
                            renderInput={(params) => (
                              // <TextField
                              //   {...params}
                              //   placeholder="Search"
                              //   error={!!registerFormErrors.selectedLanguages}
                              // />
                              <TextField
                                {...params}
                                placeholder="Search Languages"
                                error={!!registerFormErrors.selectedLanguages}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    transition: "all 0.2s",
                                    "& fieldset": {
                                      borderColor: "#D4D4D4",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#F0A61F",
                                    },
                                    "&.Mui-focused": {
                                      outline: "none",
                                      "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#F0A61F",
                                        borderWidth: "1px",
                                      },
                                      "&::after": {
                                        content: '""',
                                        position: "absolute",
                                        top: "-1px",
                                        right: "-1px",
                                        bottom: "-1px",
                                        left: "-1px",
                                        borderRadius: "inherit",
                                        border: "1px solid #F0A61F",
                                        pointerEvents: "none",
                                      },
                                    },
                                  },
                                  "& .MuiOutlinedInput-input": {
                                    "&:focus": {
                                      outline: "none",
                                    },
                                  },
                                  "& input::placeholder": {
                                    color: "#9CA3AF",
                                    opacity: 1,
                                  },
                                }}
                              />
                            )}
                          />
                        )}
                      />
                    )}
                    <div className="absolute top-[50%] translate-y-[-50%] right-[20px]">
                      <Downarrow />
                    </div>
                    <div className="text-[16px] text-red-600 mt-2">
                      {registerFormErrors.selectedLanguages &&
                        registerFormErrors.selectedLanguages.message}
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex sm:items-center sm:flex-row flex-col sm:gap-[33px] gap-[10px] w-full select-main">
                <label className="text-[17px] font-medium min-w-[167px]">
                  {t("Skills/Services")}
                </label>

                <div className="relative w-full">
                  <MyAutocomplete
                    control={registerFormControl}
                    handleSkillUpdate={handleSkillUpdate}
                    error={registerFormErrors.skills?.message}
                    providerType={providerType}
                    selectedSkills={selectedSkills}
                    setSelectedSkills={setSelectedSkills}
                    setShowBusinessModal={setShowBusinessModal}
                    setFormattedAttributes={setFormattedAttributes}
                  />
                </div>
              </div>

              <div className="flex sm:items-center sm:flex-row flex-col sm:gap-[33px] gap-[10px] w-full">
                <label className="text-[17px] font-medium min-w-[167px]">
                  {t("Location")}
                </label>
                <LocationSelector />
              </div>

              <div className="flex sm:items-center sm:flex-row flex-col sm:gap-[33px] gap-[10px] w-full">
                <label className="text-[17px] font-medium min-w-[167px]">
                  {t("About you")}
                </label>
                <div className="relative w-full">
                  <textarea
                    {...registerForm("description")}
                    name="description"
                    placeholder="Highlight passionate about your work."
                    maxLength={230}
                    className="relative border-[#D1D0CA] border-[1px] rounded h-[88px] px-[12px] w-full focus:outline-none focus:border-[#F0A61F] focus:ring-0 focus:ring-[#F0A61F]"
                  />
                  <div className="absolute text-sm text-gray-500  bottom-[-11px] right-0">
                    {t("Characters left:")} {230 - descriptionValue?.length}
                  </div>
                  <div className="text-[16px] text-red-600 mt-2">
                    {registerFormErrors.description?.message}
                  </div>
                </div>
              </div>

              <div className="flex sm:items-center sm:flex-row flex-col sm:gap-[33px] gap-[10px] w-full">
                <label className="text-[17px] font-medium min-w-[167px]">
                  {t("Referral Code")}
                </label>

                <div className="w-full">
                  <input
                    type="text"
                    value={regRefCode}
                    className="border-[#D1D0CA] border-[1px] rounded h-[48px] px-[12px] w-full focus:outline-none focus:border-[#F0A61F] focus:ring-0 focus:ring-[#F0A61F]"
                    {...registerForm("referralCode")}
                  />
                </div>
              </div>
              {/* BILLING */}
              <div>
                <h2 className="block mb-2 mt-2 text-lg font-bold text-gray-800">
                  {t("For Billing")}
                </h2>{" "}
                <div className="addresDiv  grid grid-cols-6  md:gap-2 lg:gap-2  p-4 border border-gary-200  rounded-md ">
                  <div className="col-span-6 mb-4 md:col-span-6 xl:col-span-3 lg:col-span-6">
                    <input
                      type="text"
                      id="firstName"
                      value={watchedFirstName}
                      placeholder="First Name"
                      className="w-full px-3 py-3 leading-tight text-gray-700 border border-gray-300 rounded shadow appearance-none focus:outline-none focus:shadow-outline focus:border-[#F0A61F] focus:ring-0 focus:ring-[#F0A61F]"
                      {...registerForm("firstName")}
                    />
                    {registerFormErrors.firstName && (
                      <p className="text-[16px] text-red-600">
                        {registerFormErrors.firstName.message}
                      </p>
                    )}
                  </div>
                  <div className="col-span-6 mb-4 md:col-span-6 lg:col-span-6 xl:col-span-3">
                    <input
                      type="text"
                      id="lastName"
                      value={watchedLastName}
                      {...registerForm("lastName")}
                      placeholder="Last Name"
                      className="w-full px-3 py-3 leading-tight text-gray-700 border border-gray-300 rounded shadow appearance-none focus:outline-none focus:shadow-outline  focus:border-[#F0A61F] focus:ring-0 focus:ring-[#F0A61F]"
                    />
                    {registerFormErrors.lastName && (
                      <p className="text-[16px] text-red-600">
                        {registerFormErrors.lastName.message}
                      </p>
                    )}
                  </div>
                  <div className="col-span-6 mb-4 md:col-span-6">
                    <input
                      className="w-full px-3 py-3 leading-tight text-gray-700 border border-gray-300 rounded shadow appearance-none focus:outline-none focus:shadow-outline  focus:border-[#F0A61F] focus:ring-0 focus:ring-[#F0A61F]"
                      id="Email"
                      type="email"
                      placeholder="Email"
                      {...registerForm("email")}
                    />
                    {registerFormErrors.email && (
                      <p className="text-[16px] text-red-600">
                        {registerFormErrors.email.message}
                      </p>
                    )}
                  </div>
                  <div className="col-span-6 mb-4 md:col-span-6 ">
                    <input
                      className="w-full px-3 py-3 leading-tight text-gray-700 border border-gray-300 rounded shadow appearance-none  focus:shadow-outline focus:outline-none focus:border-[#F0A61F] focus:ring-0 focus:ring-[#F0A61F]"
                      id="phoneNumber"
                      type="number"
                      placeholder="phoneNumber"
                      {...registerForm("phoneNumber")}
                    />
                    <p className="text-[16px] text-red-600">
                      {registerFormErrors.phoneNumber?.message}
                    </p>
                  </div>
                </div>
              </div>

              {/*SUBSCRIPTIONS PLAN */}
              <>
                <div className=" ">
                  <h2 className="block mb-2 mt-2 text-lg font-bold text-gray-800">
                    {t("Plan Details")}
                  </h2>
                  <PlanDisplay setSelectedPlanId={setSelectedPlanId} />
                </div>
              </>
              <div className="col-span-6 mb-4 md:col-span-6">
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    className="form-checkbox text-[#F0A61F] focus:ring-0 focus:outline-yellow-200"
                    checked={isTermsAccepted}
                    onChange={() => setIsTermsAccepted(!isTermsAccepted)}
                  />
                  <span className="ml-2">
                    {t("I accept the")}{" "}
                    <span className="text-[#F0A61F] font-semibold cursor-pointer hover:underline">
                      <ScrollToTopLink to="/terms-and-conditions">
                        {t("TermsCondition")}
                      </ScrollToTopLink>{" "}
                    </span>
                  </span>
                </label>
              </div>

              <div className="flex flex-col sm:flex-row items-center justify-center sm:space-x-4 space-y-4 sm:space-y-0 mt-11">
                <button
                  onClick={() => {
                    setIsSkipClicked(false);
                    setLoadingButton(LoadingButton.Submit);
                  }}
                  type="submit"
                  disabled={
                    !isTermsAccepted ||
                    SignUpLabourerMutation.isLoading ||
                    SignUpEmployerMutation.isLoading ||
                    ImageMutation.isLoading ||
                    SkillAttributeMutation.isLoading ||
                    isPaymentLoading
                  }
                  className={`text-white md:w-[258px] md:h-[44px] w-[150px] h-[44px] rounded font-roboto font-medium text-lg leading-6  hover:scale-105 ${
                    !isTermsAccepted
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-yellow-500 hover:bg-[#f2a10b] "
                  }`}
                >
                  {loadingButton === LoadingButton.Submit &&
                  (SignUpLabourerMutation.isLoading ||
                    SignUpEmployerMutation.isLoading ||
                    ImageMutation.isLoading ||
                    SkillAttributeMutation.isLoading ||
                    isPaymentLoading) ? (
                    <div className="flex items-center justify-center space-x-2">
                      <div
                        className="loader w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin"
                        aria-label="Loading spinner"
                      ></div>
                      <span className="text-white">{t("Please Wait...")}</span>
                    </div>
                  ) : (
                    <span>{t("Subscribe")}</span>
                  )}
                </button>

                <button
                  type="submit"
                  onClick={() => {
                    setIsSkipClicked(true);
                    setLoadingButton(LoadingButton.Skip);
                  }}
                  disabled={
                    !isTermsAccepted ||
                    SignUpLabourerMutation.isLoading ||
                    SignUpEmployerMutation.isLoading ||
                    ImageMutation.isLoading ||
                    SkillAttributeMutation.isLoading
                  }
                  className={`text-lg font-semibold leading-[24px] rounded focus:outline-none focus:ring-2 focus:ring-[#efae35] focus:ring-opacity-50 transition-all duration-300 transform  px-6 py-2  ${
                    !isTermsAccepted
                      ? "text-gray-400 cursor-not-allowed bg-gray-200 hover:bg-blue-200 hover:scale-105"
                      : "text-white bg-[#efae35] hover:bg-[#f2a10b] hover:scale-105 "
                  }`}
                >
                  {loadingButton === LoadingButton.Skip &&
                  (SignUpLabourerMutation.isLoading ||
                    SignUpEmployerMutation.isLoading ||
                    ImageMutation.isLoading ||
                    SkillAttributeMutation.isLoading) ? (
                    <div className="flex items-center justify-center space-x-2">
                      <div
                        className="loader w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin"
                        aria-label="Loading spinner"
                      ></div>
                      <span className="text-white">{t("Submitting...")}</span>
                    </div>
                  ) : (
                    t("Pay Later")
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <GenericDialog
        open={showUseWalletConfirmation}
        setOpen={setShowUseWalletConfirmation}
        title={t("subscriptionPayment.success.title")}
        message={t("subscriptionPayment.success.message")}
      />
    </>
  );
};

export default LabourRegisterForm;
