import * as yup from "yup";
import {
  NAME_REQUIRED,
  PHONE_REQUIRED,
  EMAIL_REQUIRED,
  COMMENTS_REQUIRED,
  MOBILE_INVALID,
  EMAIL_INVALID,
  NAME_MAX_LENGTH,
  MESSAGE_MAX_LENGTH,
  NAME_INVALID,
  COMMENTS_INVALID,
  WEBURL_REQUIRED,
  INVALID_WEBURL_FORMAT,
  SERVICE_SECTOR_REQUIRED,
  INVALID_SERVICE_SECTOR_FORMAT,
  INVALID_COMMENTS_FORMAT,
  TYPEOFABUSE_REQUIRED,
} from "../constants/ValidationMessage";

const MESSAGE_REGEX = /^(?!\s*$).+/;
const NAME_REGEX = /^[A-Za-z]+(?:\s[A-Za-z]+)*$/;

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const MOBILE_REGEX = /^[0-9]{10}$/;
export const ReportAbuseSchema = yup.object().shape({
  fullName: yup
    .string()
    .required(NAME_REQUIRED)
    .matches(NAME_REGEX, NAME_INVALID)
    .max(30, NAME_MAX_LENGTH),
  email: yup
    .string()
    .required(EMAIL_REQUIRED)
    .matches(EMAIL_REGEX, EMAIL_INVALID),

  phone: yup
    .string()
    .required(PHONE_REQUIRED)
    .matches(MOBILE_REGEX, MOBILE_INVALID),

  webUrl: yup.string().required(WEBURL_REQUIRED).url(INVALID_WEBURL_FORMAT),

  serviceSector: yup
    .string()
    .required(SERVICE_SECTOR_REQUIRED)
    .max(100, INVALID_SERVICE_SECTOR_FORMAT),
  comments: yup
    .string()
    .required(COMMENTS_REQUIRED)
    .matches(MESSAGE_REGEX, COMMENTS_INVALID)
    .min(20, INVALID_COMMENTS_FORMAT)

    .max(600, MESSAGE_MAX_LENGTH),

  typeOfAbuse: yup.string().required(TYPEOFABUSE_REQUIRED),
});
