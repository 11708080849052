import { useTranslation } from "react-i18next";

const AboutLanding = () => {
  const { t, i18n } = useTranslation();

  const getFontClass = () => {
    switch (i18n.language) {
      case "bn": // Bengali
      case "as": // Assamese
        return "font-notoBengali";
      case "ta": // Tamil
        return "font-notoTamil";
      case "ml": // Malayalam
        return "font-notoMalayalam";
      case "hi": // Hindi (Devanagari)
        return "font-notoDevanagari";
      case "en":
        return "font-montserrat";
      default:
        return "font-roboto";
    }
  };
  return (
    <div className="p-4   bg-green-00">
      <h2
        className={`font-bold xl:text-responsive-4xl md:text-[42px] text-responsive-lg mb-2.5 xl:leading-[78px] leading-tight text-center text-black ${getFontClass()}`}
      >
        {t("AboutUs")}
      </h2>
      <p
        className={`xl:text-responsive-2xl md:text-responsive-2xl  	 sm:text-responsive-base text-responsive-base  xl:leading-[46px] leading-normal   text-black text-justify  word-spacing-[-2px]`}
      >
        {t("AboutUs Text")}
      </p>
    </div>
  );
};

export default AboutLanding;
