import React, {
  useState,
  useEffect,
  forwardRef,
} from "react";
import ReCAPTCHA from "react-google-recaptcha";

interface ReCaptchaProps {
  onVerify: (token: string | null) => void;
}
const siteKey = "6LdLwm4qAAAAAKAZD23jV7CHfyxl9yw0lchi_Gm5";

const ReCaptcha = forwardRef<ReCAPTCHA, ReCaptchaProps>(
  ({  onVerify }, ref) => {
    const [isVerified, setIsVerified] = useState(false);

    useEffect(() => {
      onVerify(isVerified ? "verified" : null);
    }, [isVerified, onVerify]);

    const handleVerify = (token: string | null) => {
      console.log("tokentokentoken origin", token);
      setIsVerified(token !== null);
    };

    return (
      <ReCAPTCHA
        ref={ref}
        sitekey={siteKey}
        onChange={handleVerify}
        onExpired={() => setIsVerified(false)}
      />
    );
  }
);

export default ReCaptcha;
