import { useEffect, useRef, useState } from "react";
import { Megnify } from "../assets/images/AllSvg";
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Service } from "../API/types";
import useGeolocation, {
  AddressDetails,
  LocationData,
} from "../utils/useGeolocation";
import { useCompleteImageUrl } from "../utils/GeneralUtils";
import LocationSelector from "./Location/LocationSelector";
import { useLocationContext } from "../component/Context/LocationContext";
import CategoryGrid from "./CategoryGrid";
const Home = ({ serviceData }: { serviceData: Array<Service> }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState("");
  const [showPopover, setShowPopover] = useState(false);
  const { state } = useLocationContext();
  const { selectedLocationData } = state;
  const [locationData, setLocationData] = useState<LocationData | null>(null);
  const [showLocationPopup, setShowLocationPopup] = useState<boolean>(false);
  const locationSelectorRef = useRef<HTMLDivElement>(null);
  const searchLabour = (searchId: Service) => {
    if (!selectedLocationData?.lat || !selectedLocationData?.lng) {
      setShowLocationPopup(true);
      locationSelectorRef.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }
    navigate("/result", {
      state: {
        searchInput: searchId,
        location: selectedLocationData,
      },
    });
  };
  useEffect(() => {
    if (selectedLocationData?.lat && selectedLocationData?.lng) {
      setShowLocationPopup(false);
    }
  }, [selectedLocationData]);

  const placeholderText = t("Searchforservices") || "";

  const popoverRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (
        popoverRef.current &&
        !(popoverRef?.current as any)?.contains(event.target)
      ) {
        setShowPopover(false);
      }
    }

    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [showPopover]);

  //IMAGE UTILS FUNCTIONS
  const getCompleteImageUrl = useCompleteImageUrl();
  return (
    <div className="4xl:max-w-full 4xl:px-[90px] xl:max-w-[1186px] mt-[80px] px-5 2xl:max-w-[1440px] mx-auto">
      <p className="4xl:text-[1.2vw] text-[#5E6D76] text-[23px] font-medium">
        {t("Get100safeservicebyverifiedpartners")}
      </p>
      <h1
        ref={locationSelectorRef}
        className="text-[#162634] 4xl:text-[2.5vw] lg:text-[70px] text-[7vw] font-bold"
      >
        {t("whatServiceDoYouNeed")}
      </h1>
      <div className="flex gap-[14px] md:flex-nowrap flex-wrap">
        <div className="relative w-full h-full mt-4 lg:mt-0">
          <div className="absolute flex items-center justify-center top-0 bottom-0  w-[60px] h-full">
            <Megnify className={"#272D29"} />
          </div>
          <input
            className="border border-solid outline-none focus:outline-none pl-[60px] py-[18px] rounded-[14px] w-full placeholder:text-[#999485] text-black  border-[#B9B9B9] text-[21px] leading-[24px] font-medium placeholder:4xl:text-[1vw]"
            type="text"
            placeholder={placeholderText}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            // onKeyDown={(e) => {
            //   if (e.key === "Enter") {
            //     searchJobs(searchInput);
            //   }
            // }}
          />
        </div>
        <div className="flex md:gap-[14px] justify-between w-full md:w-auto relative">
          <LocationSelector />

          {showLocationPopup && (
            <>
              <div
                className="absolute top-full right-0 w-[220px] bg-red-100 border border-red-400 text-red-700 px-4 py-3  mt-2 rounded"
                style={{ right: "0", left: "auto" }}
              >
                <p>{t("Please select a location")}</p>

                <div className="absolute top-[-7px] right-[10px] rotate-45 bg-red-100 border  border-t border-l border-red-400 w-3 h-3"></div>
              </div>
            </>
          )}
        </div>
      </div>
      <CategoryGrid
        serviceData={serviceData}
        searchInput={searchInput}
        searchLabour={searchLabour}
        t={t}
        getCompleteImageUrl={getCompleteImageUrl}
      />
    </div>
  );
};

export default Home;
