import * as yup from "yup";
import {
  NAME_REQUIRED,
  PHONE_REQUIRED,
  INVALID_PHONE_FORMAT,
  INVALID_NAME_FORMAT,
  SKILL_REQUIRED,
  SKILL_ID_REQUIRED,
  LANGUAGE_ID_REQUIRED,
  LANGUAGE_NAME_REQUIRED,
  LANGUAGE_REQUIRED,
} from "../constants/ValidationMessage";
export const LabourerRegisterSchema = yup.object({
  name: yup
    .string()
    .matches(/^[a-zA-Z\s]+$/, INVALID_NAME_FORMAT)
    .required(NAME_REQUIRED),

  firstName: yup
    .string()
    .test("firstName", "First name is required", function (value) {
      const { isSkipClicked } = this.options.context || {};
      return isSkipClicked || (value && value.trim() !== "");
    }),

  lastName: yup
    .string()
    .test("lastName", "Last name is required", function (value) {
      const { isSkipClicked } = this.options.context || {};
      return isSkipClicked || (value && value.trim() !== "");
    }),
  email: yup.string().test("email", "Email is required", function (value) {
    const { isSkipClicked } = this.options.context || {};
    return isSkipClicked || (value && /\S+@\S+\.\S+/.test(value));
  }),
  phoneNumber: yup
    .string()
    .test("phoneNumber", "Phone number must be 10 digits", function (value) {
      const { isSkipClicked } = this.options.context || {};
      return isSkipClicked || (value && /^[0-9]{10}$/.test(value));
    }),

  phone: yup
    .string()
    .matches(/^\d{10}$/, INVALID_PHONE_FORMAT)
    .required(PHONE_REQUIRED)
    .nullable(),

  referralCode: yup.string().nullable(),
  selectedLanguages: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().required(LANGUAGE_ID_REQUIRED),
        name: yup.string().required(LANGUAGE_NAME_REQUIRED),
      })
    )
    .min(1, LANGUAGE_REQUIRED)
    .required(LANGUAGE_REQUIRED),

  description: yup
    .string()
    // .matches(MESSAGE_REGEX, MESSAGE_INVALID)
    .max(230),

  skills: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().required(SKILL_ID_REQUIRED),
      })
    )
    .min(1, SKILL_REQUIRED)
    .required(SKILL_REQUIRED)
    .test(
      "validate-skills",
      "Skills must be properly updated",
      function (skills) {
        console.log("Skills after form update:", skills);
        if (!skills || skills.length === 0) return false;
        return true;
      }
    ),
});
