import React, { useState } from "react";
import Slider from "react-slick";
import { EyeIcon, MessageIcon } from "../../assets/images/AllSvg";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { ClockIcon, StarIcon } from "@heroicons/react/20/solid";
import { useServiceCategoryQuery } from "../../API/api";
import AwardThisJob from "../Models/AwardThisJob";
import { useTranslation } from "react-i18next";
import { getSkillName } from "../../utils/utils";
import { RemoveFavoriteModal } from "../SearchServices/RemoveFavoriteModal";
import { useCompleteImageUrl } from "../../utils/GeneralUtils";
import { FaEnvelope, FaSpinner } from "react-icons/fa";
import SimilarLabourerSlider from "./SimilarLabourerSlider";
import {
  EmployerReviewDto,
  EmployerReviewUserDto,
  FavouritesDto,
  Language,
  Skill,
} from "../../API/types";
import CircularProgressBar from "../CircularProgressBar";
export enum ServiceProviderType {
  Individual = 1,
  Business = 2,
}
const ReviewDiv = ({
  details,
  handleJobAwardClick,
  handleChatClick,
  handleFavorites,
  handleClick,
  show,
  languages,
  skill,
  reviewsData,
  reviewsLoading,
  setShowReview,
  allResult,
  setOpenAwardMenu,
  openAwardMenu,
  favoriteLoadingStates,
  favouritesData,
  favLabourerId,
  chatRoomLoadingState,
  awardJobLoadingState,
  createJobError,
  isFav,
  userData,
  isLabourerLoading,
}: {
  details: any;
  handleJobAwardClick: any;
  handleChatClick: any;
  handleFavorites: any;
  handleClick: any;
  show: any;
  languages: any;
  skill: any;
  reviewsData: any;
  reviewsLoading: boolean;
  setShowReview: React.Dispatch<React.SetStateAction<boolean>>;
  allResult: any;
  setOpenAwardMenu: React.Dispatch<React.SetStateAction<boolean>>;
  openAwardMenu: boolean;
  favoriteLoadingStates: any;
  favouritesData: any;
  favLabourerId: any;
  chatRoomLoadingState: boolean;
  awardJobLoadingState: boolean;
  createJobError: string | null;
  isFav: boolean;
  userData: any;
  isLabourerLoading: boolean;
}) => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [providerType, setProviderType] = useState<ServiceProviderType>(
    ServiceProviderType.Individual
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemForModal, setSelectedItemForModal] = useState<any>(null);
  const { data: serviceData } = useServiceCategoryQuery();
  const { t } = useTranslation();
  const handleFavoriteClick = (item: FavouritesDto) => {
    const isFavorite = favouritesData?.find(
      (fav: FavouritesDto) => fav.labourerId === favLabourerId
    );
    if (isFavorite) {
      setSelectedItemForModal(favLabourerId);
      setIsModalOpen(true);
    } else {
      handleFavorites(favLabourerId);
    }
  };

  const confirmRemoveFavorite = () => {
    if (selectedItemForModal) {
      handleFavorites(selectedItemForModal);
      setIsModalOpen(false);
      setSelectedItemForModal(null);
    }
  };
  const isFavourite = favouritesData?.find(
    (fav: FavouritesDto) => fav?.labourerId === favLabourerId
  );

  //IMAGE UTILS FUNCTIONS
  const getCompleteImageUrl = useCompleteImageUrl();

  const isHybridOrEmployer =
    userData?.user?.roles.includes("employer") ||
    (userData?.user?.roles.includes("employer") &&
      userData?.user?.roles.includes("labourer"));

  const workingDays = ["Mon", "Tue", "Wed", "Thu", "Fri"];
  const providerDetails = {
    serviceProviderType: 1,
    serviceName: "Miya Textiles",
    gstId: "GST7485HGF",
    openTime: "9:30 AM",
    closingTime: "8:30 PM",
  };
  return (
    <div>
      <div className="max-w-[811px] mt-[45px] sm:px-5 px-3 mx-auto ">
        {!details ? (
          <div>
            <div className="bg-[#272D29] flex justify-center rounded-[11px] pt-[19px] px-[24px] pb-[29px]  ">
              <h1 className="md:text-responsive-xl text-responsive-base font-semibold text-white">
                {t("Details Page")}
              </h1>
            </div>
            <div className="mt-7 border border-[#D1D0CA] mb-12 rounded-xl divide-y-2 divide-[#E9E8E3]">
              <div className="flex justify-center items-center h-[200px] ">
                <p className="text-[#F0A61F] font-semibold md:text-responsive-xl text-responsive-lg">
                  {t("No labourer data found")}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <section>
            <div className="bg-[#272D29] rounded-[11px] pt-[19px] px-[24px] pb-[29px]">
              <div className="flex flex-wrap justify-center gap-4 md:flex-nowrap md:justify-start">
                <div className="relative w-fit">
                  <div className="h-[107px] w-[107px] rounded-full overflow-hidden flex justify-center items-center">
                    {details?.profileUrl ? (
                      <img
                        src={getCompleteImageUrl(details?.profileUrl)}
                        className="object-cover h-full"
                        alt="profile"
                      />
                    ) : (
                      <img
                        src={require("../../assets/images/user_default.png")}
                        alt="profile"
                        className="h-full object-cover object-[94%]"
                      />
                    )}
                  </div>
                  <div className="absolute -bottom-2 left-1/2 -translate-x-1/2 h-[20px] w-[41px] bg-[#00C54F] rounded-full flex items-center justify-center">
                    <p className="text-white text-[13px] font-roboto">
                      {t("Live")}
                    </p>
                  </div>
                </div>
                <div className="w-full">
                  <div className="flex flex-wrap items-center justify-center w-full md:justify-between">
                    <h2 className="text-[30px] leading-[63px] text-white font-semibold md:mr-0 mr-[20px]">
                      {t(details?.name)}
                    </h2>
                    <div className="flex items-center gap-3">
                      {providerDetails?.serviceProviderType ===
                      ServiceProviderType.Business ? (
                        <button
                          onClick={() => handleChatClick(details)}
                          className="bg-[#FBE9C7] border border-white/40 text-black rounded-[34px] h-[44px] px-5 flex justify-center items-center gap-1 text-[11px] font-semibold"
                        >
                          <FaEnvelope className="w-5 h-5" />
                          <span>Enquiry</span>
                        </button>
                      ) : (
                        <button
                          onClick={() => handleChatClick(details)}
                          className="border-[#999485] border-[1px] rounded-[34px] w-[44px] h-[44px] flex justify-center items-center"
                          disabled={chatRoomLoadingState}
                        >
                          {chatRoomLoadingState ? (
                            <FaSpinner className="inline-block animate-spin text-[#F0A61F] w-5 h-5" />
                          ) : (
                            <MessageIcon />
                          )}
                        </button>
                      )}

                      {isHybridOrEmployer && (
                        <button
                          onClick={() => handleFavoriteClick(details)}
                          className="border-[#999485] border rounded-full w-[34px] h-[34px] md:w-[44px] md:h-[44px] flex justify-center items-center hover:bg-white transition-colors duration-300"
                        >
                          {favoriteLoadingStates[favLabourerId]?.add ||
                          favoriteLoadingStates[favLabourerId]?.remove ? (
                            <div className="w-5 h-5 border-2 border-t-[#F0A61F] border-r-[#F0A61F] border-b-[#F0A61F] border-l-transparent rounded-full animate-spin" />
                          ) : isFavourite ? (
                            <AiFillHeart className="w-6 h-6 text-red-400 transition-colors duration-300" />
                          ) : (
                            <AiOutlineHeart className="w-6 h-6 text-[#999485] transition-colors duration-300" />
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="flex gap-4 md:justify-start justify-center md:mt-0 mt-[10px]">
                    <button
                      onClick={() => handleClick(details.id)}
                      className="bg-[#999485] text-white rounded-[34px] h-[39px] w-[125px] flex justify-center items-center gap-1 text-[11px] font-semibold mr-[11px]"
                    >
                      {show.status && show.id === details.id ? (
                        <p>{details.phoneNumber}</p>
                      ) : (
                        <div className="flex items-center justify-center gap-1">
                          <EyeIcon color="#fff" /> {t("Show Phone")}
                        </div>
                      )}
                    </button>
                    <button
                      onClick={() => handleJobAwardClick(details)}
                      className="bg-[#FBE9C7] border border-white/40 text-black rounded-[34px] h-[39px] px-5 flex justify-center items-center gap-1 text-[11px] font-semibold"
                      disabled={awardJobLoadingState}
                    >
                      {awardJobLoadingState ? (
                        <>
                          <span>awarding</span>
                          <FaSpinner className="inline-block text-[#F0A61F] animate-spin ml-2 w-5 h-5" />
                        </>
                      ) : providerDetails?.serviceProviderType ===
                        ServiceProviderType.Business ? (
                        "Business"
                      ) : (
                        t("Award This Job")
                      )}
                    </button>

                    {createJobError && (
                      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                        <strong>Error:</strong> {createJobError}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-7 border border-[#D1D0CA] mb-12 rounded-xl divide-y-2 divide-[#E9E8E3]">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Left column */}
                <div>
                  <div className="px-3 py-3 sm:px-6">
                    <h4 className="text-sm text-[#272D29] font-medium">
                      {t("Description")}
                    </h4>
                    <p className="md:text-[19px] text-base font-medium">
                      {t(details?.description || "description not found")}
                    </p>
                  </div>
                  {providerDetails?.serviceProviderType ===
                    ServiceProviderType.Business && (
                    <div className="px-3  sm:px-6">
                      <h2 className="text-lg text-[#272D29] font-semibold">
                        {providerDetails.serviceName}
                      </h2>
                    </div>
                  )}
                  <div className="py-3 pl-3 sm:pl-6">
                    <h4 className="text-[#272D29] text-sm font-medium">
                      {t("Location")}
                    </h4>
                    <h3 className="text-[#272D29] text-lg font-medium">
                      {t(details?.location || "Location not found")}
                    </h3>

                    {details?.distance && (
                      <p className="text-[#7C786D] text-[13px] font-medium">
                        {details.distance.toFixed(2)} {t("Km from you")}
                      </p>
                    )}
                  </div>
                  {providerDetails?.serviceProviderType ===
                    ServiceProviderType.Individual && (
                    <div className="py-3 pl-3 sm:pl-6">
                      <h4 className="text-[#272D29] text-sm font-medium mb-2">
                        {t("Languages")}
                      </h4>
                      <div className="flex flex-wrap items-center gap-2">
                        {details?.languages?.map((service: any, index: any) => {
                          const matchingLanguage = languages?.find(
                            (item: any) => service?.languageId === item?.id
                          );
                          return (
                            <span
                              key={index}
                              className="bg-[#E9E8E3] rounded-[24px] text-[#272D29] font-roboto text-[14px] p-[4px_13px]"
                            >
                              {t(
                                matchingLanguage?.name || "Language not found"
                              )}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>

                {/* Right column */}
                {providerDetails?.serviceProviderType ===
                  ServiceProviderType.Business && (
                  <div>
                    <div className="py-3 pl-3 sm:pl-6">
                      <p className="text-[#272D29] text-lg font-medium">
                        {providerDetails.gstId}
                      </p>
                    </div>
                    <div className="py-3 pl-3 sm:pl-6">
                      <h4 className="text-[#272D29] text-sm font-medium mb-2">
                        {t("Working Days")}
                      </h4>
                      <div className="flex flex-wrap items-center gap-2">
                        {workingDays?.map((day: any, index: any) => (
                          <span
                            key={index}
                            className="bg-[#E9E8E3] rounded-[24px] text-[#272D29] font-roboto text-[14px] p-[4px_13px]"
                          >
                            {t(day)}
                          </span>
                        ))}
                      </div>
                    </div>
                    <div className="py-3 pl-3 sm:pl-6">
                      <h4 className="text-[#272D29] text-sm font-medium mb-2">
                        {t("Working Hours")}
                      </h4>
                      <div className="flex items-center justify-between bg-[#E9E8E3] p-2 rounded-md mb-2 mr-10">
                        <div className="flex items-center">
                          <ClockIcon className="w-4 h-4 text-[#272D29] mr-2" />
                          <div className="text-[#272D29] text-sm">
                            {t("Opening Time")}
                          </div>
                        </div>
                        <div className="text-[#272D29] font-medium">
                          {" "}
                          {providerDetails.openTime}
                        </div>
                      </div>
                      <div className="flex items-center justify-between bg-[#E9E8E3] p-2 rounded-md mr-10">
                        <div className="flex items-center">
                          <ClockIcon className="w-4 h-4 text-[#272D29] mr-2" />
                          <span className="text-[#272D29] text-sm">
                            {t("Closing Time")}
                          </span>
                        </div>
                        <span className="text-[#272D29] font-medium">
                          {providerDetails.closingTime}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* Skills section */}
              <div className="py-3 pb-5 px-3 sm:px-6">
                <h4 className="text-[#272D29] text-sm font-medium mb-2">
                  {providerDetails?.serviceProviderType ===
                  ServiceProviderType.Business
                    ? t("Services")
                    : t("SkillsWithExperience")}
                  {/* {t("SkillsWithExperience")} */}
                </h4>
                <div className="flex flex-wrap items-center gap-2">
                  {details?.skills?.map((service: any, index: any) => (
                    <span
                      key={index}
                      className="bg-[#E9E8E3] rounded-[24px] text-[#272D29] font-roboto text-[14px] p-[4px_13px]"
                    >
                      {t(
                        `${getSkillName(service?.skillId, serviceData)} (${
                          service?.experience
                        } ${t("years")})`
                      )}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </section>
        )}

        <div className="flex justify-between items-center mb-[30px]">
          <h1 className="sm:text-[28px] text-[22px] font-semibold">
            {t("Reviews")}
          </h1>
          {reviewsData?.employerReviews?.length !== 0 && (
            <button
              onClick={() => {
                setShowReview(true);
                window.scrollTo(0, 0);
              }}
              className="text-[#F0A61F] text-[17px] underline mr-[110px] cursor-pointer bg-transparent border-none"
            >
              {t("Show All")}
            </button>
          )}
        </div>
        {reviewsLoading ? (
          <div className="mb-[30px] bg-[#fffcf6] h-[50px] text-center flex items-center justify-center font-semibold text-lg">
            {/* {t("Loading reviews...")} */}
            <CircularProgressBar />
          </div>
        ) : reviewsData?.employerReviews?.length !== 0 ? (
          <div className=" mb-[140px]">
            <Slider {...settings} className="detailslider">
              {reviewsData?.employerReviews?.map(
                (review: EmployerReviewDto) => {
                  const matchedLabourer = reviewsData?.users.find(
                    (user: EmployerReviewUserDto) =>
                      user?.id === review?.job.userId
                  );
                  return (
                    <div
                      key={review?.id}
                      className="p-6 border-[1px] border-[#D1D0CA] rounded-lg"
                    >
                      <div className=" rounded-xl ">
                        <div className="flex items-start space-x-4">
                          <img
                            className="h-16 w-16 rounded-full object-cover border-2 border-indigo-500"
                            src={
                              matchedLabourer?.profileUrl ||
                              require("../../assets/images/user_default.png")
                            }
                            alt=""
                          />
                          <div className="flex-1">
                            <h3 className="text-lg font-semibold text-gray-800">
                              {t(matchedLabourer?.name)}
                            </h3>
                            <div className="flex items-center mt-1">
                              {[0, 1, 2, 3, 4].map((rating) => (
                                <StarIcon
                                  key={rating}
                                  className={`h-5 w-5 ${
                                    review?.employerRatings[0]?.rating > rating
                                      ? "text-yellow-400 fill-current"
                                      : "text-gray-300"
                                  }`}
                                />
                              ))}
                            </div>
                          </div>
                        </div>
                        <p className="mt-4 text-gray-600 italic">
                          "{t(review?.review)}"
                        </p>
                      </div>
                    </div>
                  );
                }
              )}
            </Slider>
          </div>
        ) : (
          <div className="mb-[30px] bg-[#fffcf6] h-[50px] text-center flex items-center justify-center font-semibold text-lg">
            {t("No review found")}
          </div>
        )}
      </div>
      {!isFav && (
        <SimilarLabourerSlider
          allResult={allResult}
          skill={skill}
          t={t}
          favLabourerId={favLabourerId}
          favouritesData={favouritesData}
          serviceData={serviceData}
        />
      )}

      <AwardThisJob
        setOpenAwardMenu={setOpenAwardMenu}
        openAwardMenu={openAwardMenu}
      />
      <RemoveFavoriteModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedItemForModal(null);
        }}
        onConfirm={confirmRemoveFavorite}
        itemName={selectedItemForModal?.name}
      />
    </div>
  );
};

export default ReviewDiv;
