import { toast } from "react-toastify";
import { initPayment, proceedPaymentUsingWallet } from "../API/subscriptionApi";
import { PAYMENT_STATUS } from "../component/LabourerRegisterForm/LabourerRegisterForm";
import { dataURLtoBlob, getFileTypeFromUrl } from "./utils";
import { UserDetailResponse } from "../API/types";
import { LabourerSkillAttributeDto } from "../API/api";
interface FormDataDto {
  phone: string;
  email: string;
  firstName: string;
  lastName: string;
}
export const handleImageUpload = async (
  url: string,
  setImageErrorStatus: (status: boolean) => void,
  ImageMutation: any
) => {
  if (!url) {
    toast.error("No image URL provided.");
    setImageErrorStatus(true);
    return;
  }

  try {
    const blob = dataURLtoBlob(url);
    const fileType = getFileTypeFromUrl(url);
    const file = new File([blob], `image.${fileType.split("/")[1]}`, {
      type: fileType,
      lastModified: Date.now(),
    });
    const imageResponse = await ImageMutation.mutateAsync(file);

    if (imageResponse) {
      // toast.success("Profile image uploaded successfully");
      return imageResponse;
    }
  } catch (error) {
    console.error("Error occurred during image upload:", error);
    toast.error("Failed to upload profile image.");
    setImageErrorStatus(true);
  }
};

export const saveSkillAttributes = async (
  attributes: LabourerSkillAttributeDto[],
  SkillAttributeMutation: any
) => {
  try {
    await SkillAttributeMutation.mutateAsync(attributes);
    // toast.success("Skill attributes saved successfully.");
  } catch (error) {
    console.error("Error saving skill attributes:", error);
    toast.error("Failed to save skill attributes.");
  }
};

export const initiateSubscription = async (
  phone: string,
  userInfo: UserDetailResponse,
  email: string,
  firstName: string,
  lastName: string,
  subscriptionPlanId: number
) => {
  try {
    const response = await initPayment(subscriptionPlanId);
    if (response.amountToBePaid === 0) {
      await proceedPaymentUsingWallet({
        paymentUuid: response.paymentUUID,
        subscriptionId: response.subscriptionId,
      });
      return { status: PAYMENT_STATUS.WALLET_PAYMENT };
    } else {
      return {
        status: PAYMENT_STATUS.PAYMENT_GATEWAY,
        data: {
          buyerEmail: email,
          buyerFirstName: firstName,
          buyerLastName: lastName,
          buyerPhone: phone,
          userId: userInfo?.user?.id ?? 0,
          userUuid: userInfo?.user?.userId ?? "",
          subscriptionPlanId,
          subscriptionId: response.subscriptionId,
          paymentUuid: response.paymentUUID,
        },
      };
    }
  } catch (error) {
    console.error("Error initiating subscription payment:", error);
    throw error;
  }
};

export const handlePayment = async (
  formData: FormDataDto,
  userData: UserDetailResponse,
  selectedPlanId: number | undefined,
  setIsPaymentLoading: (isLoading: boolean) => void,t:any
) => {
  if (!userData?.user?.id || !userData.user?.userId) {
    throw new Error(t("Missing required user data for payment initialization."));
  }
  if (!selectedPlanId) {
    throw new Error(t("No subscription plan selected."));
  }

  try {
    const paymentResult = await initiateSubscription(
      formData.phone,
      userData,
      formData.email,
      formData.firstName,
      formData.lastName,
      selectedPlanId
    );

    if (paymentResult.status === PAYMENT_STATUS.PAYMENT_GATEWAY) {
      // toast.success("Registration successful!");
      return paymentResult.data;
    }
  } catch (error) {
    console.error("Error initiating subscription payment:", error);
    throw new Error(t("Payment process failed. Please try again."));
  } finally {
    setIsPaymentLoading(false);
  }
};

export const showToast = (type: "success" | "error", message: string) => {
  type === "success" ? toast.success(message) : toast.error(message);
};
