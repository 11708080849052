import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { FeedbackDetailsDto, useFeedbackMaster } from "../API/api";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FeedbackSchema } from "../schema/feedbackSchema";
import { isAuthenticated } from "../utils/jwtUtils";
import { UserType } from "../API/types";
import { useSendFeedback } from "../component/services/Mutation";
import { Settings } from "react-slick";
import { Card, CardBody, Typography, Button } from "@material-tailwind/react";
import { PhoneIcon, EnvelopeIcon, MapPinIcon } from "@heroicons/react/24/solid";
import { FiPhone, FiMail, FiMapPin, FiDownload } from "react-icons/fi";
import ReCaptcha from "../component/ReCAPTCHA/ReCAPTCHA";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
interface ContactUsProps {
  onRegisterClicked: (UserType?: UserType) => void;
}
interface FeedbackFormDto {
  name: string;
  email: string;
  phone: string;
  message: string;
}

const testimonials = [
  {
    id: 1,
    para1: "contactPara3",
    para2: "contactPara4",
    name: "AlbusDumbledore",
    role: "ManagerHowarts",
    img: require("../assets/images/user1.jpeg"),
  },
  {
    id: 2,
    para1: "contactPara3",
    para2: "contactPara4",
    name: "AlbusDumbledore",
    role: "ManagerHowarts",
    img: require("../assets/images/user1.jpeg"),
  },
  {
    id: 3,
    para1: "contactPara3",
    para2: "contactPara4",
    name: "AlbusDumbledore",
    role: "ManagerHowarts",
    img: require("../assets/images/user1.jpeg"),
  },
  {
    id: 4,
    para1: "HasBeen",
    para2: "",
    name: "AlbusDumbledore",
    role: "ManagerHowarts",
    img: require("../assets/images/user1.jpeg"),
  },
];

const ContactUs: React.FC<ContactUsProps> = ({ onRegisterClicked }) => {
  const { t } = useTranslation();
  const placeholderName = t("Name") || "";
  const placeholderEmail = t("Email Id") || "";
  const placeholderPhone = t("Phone Number") || "";
  const placeholderMessage = t("Message") || "";

  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { data } = useFeedbackMaster();
  const sendFeedbackMutation = useSendFeedback();
  const {
    register: contactForm,
    handleSubmit: contactFormSubmission,
    formState: { errors: contactFormErrors },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(FeedbackSchema),
    mode: "onChange",
  });

  const [isReCaptchaVerified, setIsReCaptchaVerified] = useState(false);
  const [captchaError, setCaptchaError] = useState<string>("");
  const reCaptchaRef = useRef<any>(null);
  const handleReCaptchaVerify = (token: string | null) => {
    if (token) {
      setIsReCaptchaVerified(true);
      setCaptchaError("");
    } else {
      setIsReCaptchaVerified(false);
    }
  };
  const onSubmit = async (data: FeedbackFormDto) => {
    if (isReCaptchaVerified) {
      try {
        const feedbackDetails: FeedbackDetailsDto = {
          name: data.name,
          message: data.message,
          emailId: data.email,
          status: "NEW",
          phoneNumber: data.phone,
        };
        await sendFeedbackMutation.mutateAsync(feedbackDetails);
        reset();
        setIsReCaptchaVerified(false);
        if (reCaptchaRef.current) {
          reCaptchaRef.current.reset();
        }
      } catch (error) {
        console.error("An error occurred during mutation:", error);
      }
    } else {
      setCaptchaError("Please verify that you are not a robot.");
    }
  };
  const messageValue = watch("message", "");

  return (
    <>
      <div className="4xl:max-w-full 4xl:px-[90px] xl:max-w-[1186px] mt-[45px]  2xl:max-w-[1440px] mx-auto ">
        <div className="p-4 sm:p-6 lg:p-8">
          <h1 className="md:text-responsive-3xl text-responsive-2xl font-bold mb-6 text-center lg:text-left">
            {t("Contact Us")}
          </h1>
          <div className="bg-[#FFFCF6] rounded-[20px] shadow-box overflow-visible relative min-h-[320px] ">
            <div className="flex flex-col lg:flex-row items-center lg:items-start relative">
              <div className="lg:w-3/5 px-4 sm:px-8 lg:px-10 py-4 lg:py-6">
                <h2 className="text-responsive-2xl text-black font-bold text-center lg:text-left">
                  {t("Feedback")}
                </h2>
                <p className="text-responsive-base text-black max-w-[800px] font-medium mt-3 text-center lg:text-left">
                  {t("contactPara1")}
                </p>
                <p className="text-responsive-lg text-black max-w-[800px] font-medium mt-4 text-center lg:text-left">
                  {t("contactPara2")}
                </p>
                <div className="flex justify-center lg:justify-start">
                  {!isAuthenticated() && (
                    <button
                      onClick={() => {
                        onRegisterClicked();
                      }}
                      className="bg-yellow-400 hover:bg-yellow-500 text-white font-bold py-2 px-4 rounded mt-4"
                    >
                      {t("Sign up")}
                    </button>
                  )}
                </div>
              </div>
              <div className="lg:w-2/5 relative mt-6 lg:mt-0 lg:pl-4">
                <div className="h-full relative overflow-visible flex justify-center lg:justify-end">
                  <img
                    src={require("../assets/images/feedback.png")}
                    alt="Feedback illustration"
                    className="max-w-full h-[350px] object-cover lg:absolute lg:top-[-50px] lg:right-0"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:my-[119px] my-0 pr-2 ">
          <h1 className="md:text-responsive-3xl text-responsive-2xl md:text-start text-center font-semibold mb-20">
            {t("ClientTestimonials")}
          </h1>
          <div className="lg:mt-8 detailslider">
            <Slider {...settings}>
              {testimonials?.map((testimonial: any) => (
                <div key={testimonial.id} className="mb-5 flex justify-center">
                  <div className="lg:p-6 p-4 shadow-lg border max-w-[364px] w-full rounded-xl">
                    <div className="flex flex-col gap-3">
                      <p className="text-black text-[18px]">
                        {t(testimonial.para1)}
                      </p>
                      {testimonial.para2 && (
                        <p className="text-black text-[18px]">
                          {t(testimonial.para2)}
                        </p>
                      )}
                    </div>
                    <div className="mt-10 flex items-center gap-4">
                      <div className="h-[58px] w-[58px] rounded-full overflow-hidden flex justify-center items-center">
                        <img
                          src={testimonial.img}
                          alt="user"
                          className="object-cover w-[58px] h-[58px]"
                        />
                      </div>
                      <div>
                        <h4 className="text-[#0A2640]  text-responsive-sm font-bold">
                          {t(testimonial.name)}
                        </h4>
                        <p className="text-[#0A2640] md:text-base text-responsive-sm">
                          {t(testimonial.role)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>

        <div className="md:my-[119px] my-0 ">
          <h1 className="md:text-responsive-3xl text-responsive-2xl text-center md:text-start font-semibold">
            {t("CompanyCallCentrers")}
          </h1>
          <div className="mt-7 grid md:grid-cols-2 grid-cols-1 gap-8 p-3 ">
            <div className="w-full shadow-testimonial rounded-xl py-[22px] px-6 pb-5">
              <div className="flex items-center justify-between">
                <h2 className="text-[#0A2640] text-[21px] font-bold">
                  {t("Add1")}
                </h2>
                <img src={require("../assets/images/fleg.png")} alt="fleg" />
              </div>
              <p className="text-xl mt-2 max-w-[453px]">{t("detailAdd1")} </p>
              <p className="text-xl mt-2 max-w-[453px]">{t("call1")}</p>
            </div>
            <div className="w-full shadow-testimonial rounded-xl py-[22px] px-6 pb-5">
              <div className="flex items-center justify-between">
                <h2 className="text-[#0A2640] text-[21px] font-bold">
                  {t("Add2")}
                </h2>
                <img src={require("../assets/images/fleg.png")} alt="fleg" />
              </div>
              <p className="text-xl mt-2 max-w-[453px]">{t("detailAdd2")}</p>
              <p className="text-xl mt-2 max-w-[453px]">{t("call2")}</p>
            </div>
            <div className="w-full shadow-testimonial rounded-xl py-[22px] px-6 pb-5">
              <div className="flex items-center justify-between">
                <h2 className="text-[#0A2640] text-[21px] font-bold">
                  {t("Add1")}
                </h2>
                <img src={require("../assets/images/fleg.png")} alt="fleg" />
              </div>
              <p className="text-xl mt-2 max-w-[453px]">{t("detailAdd1")} </p>
              <p className="text-xl mt-2 max-w-[453px]">{t("call1")}</p>
            </div>
            <div className="w-full shadow-testimonial rounded-xl py-[22px] px-6 pb-5">
              <div className="flex items-center justify-between">
                <h2 className="text-[#0A2640] text-[21px] font-bold">
                  {t("Add2")}
                </h2>
                <img src={require("../assets/images/fleg.png")} alt="fleg" />
              </div>
              <p className="text-xl mt-2 max-w-[453px]">{t("detailAdd2")}</p>
              <p className="text-xl mt-2 max-w-[453px]">{t("call2")}</p>
            </div>
          </div>
        </div>

        {/* contct start */}
        <div className="mt-4">
          <div className="container mx-auto px-4 ">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className=" border rounded-lg shadow-lg overflow-hidden">
                <div className="px-6 py-2">
                  <div className="flex items-center text-xl font-semibold mb-2">
                    <FiMail className="mr-2" />
                    <span>{t("Email Us")}</span>
                  </div>
                </div>
                <div className="px-6 ">
                  <p className="text-lg">
                    <a
                      href="mailto:support@eaidex.com"
                      className="text-blue-600 hover:underline"
                    >
                      {t("support@eaidex.com")}
                    </a>
                  </p>
                  <p className="text-sm text-gray-600 mt-2">
                    {t("We'll respond within 24 hours")}
                  </p>
                </div>
              </div>

              <div className="border rounded-lg shadow-lg overflow-hidden">
                <div className="px-6 py-2">
                  <div className="flex items-center text-xl font-semibold mb-2">
                    <FiPhone className="mr-2" />
                    <span>{t("Call Us")}</span>
                  </div>
                </div>
                <div className="px-6 ">
                  <p className="text-lg">
                    {t("Toll-free:")}
                    <a
                      href="tel:1-800-123-4567"
                      className="text-blue-600 hover:underline"
                    >
                      1-800-123-4567
                    </a>
                  </p>
                  <p className="text-sm text-gray-600 mt-2">
                    {t("Available 24/7 for your inquiries")}
                  </p>
                </div>
              </div>

              <div className=" border rounded-lg shadow-lg overflow-hidden">
                <div className="px-6 py-2">
                  <div className="flex items-center text-xl font-semibold mb-2">
                    <FiDownload className="mr-2" />
                    <span>{t("Download Our App")}</span>
                  </div>
                </div>
                <div className="px-6 ">
                  <img
                    src={require("../assets/images/playstore.png")}
                    alt="playstore"
                    className="h-16"
                  />
                  <p className="text-sm text-gray-600 mt-2">
                    {t("Get our app for easy access on the go")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* contct end */}
        <div className="mt-[119px] mb-[85px]  ">
          <div className="p-4">
            <p className="text-[#272D29]  text-responsive-lg md:text-responsive-2xl font-semibold">
              {t("QueriesText")}
            </p>
            <p className="mt-5 text-xl  font-medium">{t("HelpText")}</p>
            <p className="mt-5 text-lg  font-medium border-b-2 border-[#F0A61F] w-fit">
              {t("Feedback")}
            </p>
          </div>

          <form onSubmit={contactFormSubmission(onSubmit)}>
            <div className="flex flex-col gap-5 px-2 md:px-4">
              <div>
                <input
                  {...contactForm("name")}
                  name="name"
                  id="name"
                  type="text"
                  placeholder={placeholderName}
                  className="border-[#D1D0CA] border-[1px] rounded mt-[34px] w-full h-[48px] pl-3  focus:outline-none"
                />
                <p className="text-[16px] text-red-600">
                  {contactFormErrors.name?.message}
                </p>
              </div>
              <div>
                <input
                  {...contactForm("email")}
                  type="email"
                  id="email"
                  name="email"
                  placeholder={placeholderEmail}
                  className="border-[#D1D0CA] border-[1px] rounded mt-[34px] w-full h-[48px] pl-3  focus:outline-none"
                />
                <p className="text-[16px] text-red-600">
                  {contactFormErrors.email?.message}
                </p>
              </div>
              <div>
                <input
                  {...contactForm("phone")}
                  type="number"
                  id="phone"
                  name="phone"
                  placeholder={placeholderPhone}
                  className="border-[#D1D0CA] border-[1px] rounded mt-[34px] w-full h-[48px] pl-3  focus:outline-none"
                />
                <p className="text-[16px] text-red-600">
                  {contactFormErrors.phone?.message}
                </p>
              </div>

              <div>
                <textarea
                  {...contactForm("message")}
                  name="message"
                  id="message"
                  placeholder={placeholderMessage}
                  className="border-[#D1D0CA] border-[1px] rounded mt-[34px] w-full h-[148px] p-3  focus:outline-none"
                />
                <div className="flex justify-between items-center mt-1">
                  <p className="text-[16px] text-red-600">
                    {contactFormErrors.message?.message}
                  </p>
                  <div className="text-sm text-gray-500">
                    Characters left: {600 - messageValue.length}
                  </div>
                </div>
              </div>

              <div className=" w-9 ">
                <ReCaptcha
                  ref={reCaptchaRef}
                  onVerify={handleReCaptchaVerify}
                />
              </div>
              {<p className="text-red-600 text-[16px]">{captchaError}</p>}

              <button
                type="submit"
                className="min-w-[127px] py-3 px-16 rounded mt-16 mx-auto flex justify-center items-center text-white text-lg font-medium bg-[#484e4a] hover:bg-[#272D29] disabled:bg-gray-400 disabled:cursor-not-allowed transition-colors duration-300"
                disabled={sendFeedbackMutation?.isLoading}
              >
                {sendFeedbackMutation?.isLoading ? (
                  <>
                    <span className="animate-spin mr-2">&#8987;</span>
                    Submitting...
                  </>
                ) : (
                  t("SendFeedback")
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
