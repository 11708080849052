import React, { useEffect, useRef, useState } from "react";
import { Megnify } from "../assets/images/AllSvg";
import AboutLanding from "../component/Landing/AboutLanding";
import OurServices from "../component/Landing/OurServices";
import Needjob from "../component/Landing/Needjob";
import SolutionsandSupport from "../component/Landing/SolutionsandSupport";
import Benefits from "../component/Landing/Benefits";
import ReplyQoutes from "../component/Landing/ReplyQoutes";
import DowloadApp from "../component/Landing/DowloadApp";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  acknowledgeCookieAcceptance,
  hasCookieAcceptanceAcknowledged,
  useUserMeQuery,
} from "../API/api";
import CookieConsentBanner from "../component/Landing/CookieConsentBanner";
import { UserType } from "../API/types";

const Landing = ({
  compRef,
  solutionRef,
  benefitsRef,
  servicesRef,
  serviceData,
  onRegisterClicked,
  preSelectedUserType,
  resetPreselectedUserType,
  open,
  setOpen,
}: {
  onRegisterClicked: (userType: UserType) => void;
  compRef: React.RefObject<any>;
  solutionRef: React.RefObject<any>;
  benefitsRef: React.RefObject<any>;
  servicesRef: React.RefObject<any>;
  serviceData: any;
  preSelectedUserType: UserType | undefined;
  resetPreselectedUserType: () => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const placeholderText = t("Searchforservices") || "";
  const { data: user } = useUserMeQuery();
  const { state } = useLocation();
  const { targetId } = state || {};
  const [showCookieConsentBanner, setShowCookieConsentBanner] = useState(
    !hasCookieAcceptanceAcknowledged()
  );
  useEffect(() => {
    const el = document.getElementById(targetId);
    if (el) {
      el.scrollIntoView();
    }
  }, [targetId]);

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const referralCode = queryParams.get("referralCode");
    if (referralCode) {
      // Open the modal
      setOpen(true);
    }
  }, [location.search, setOpen]);

  const handleServiceClick = (serviceName: string) => {
    const service = serviceData.find(
      (item: any) => item.name.toLowerCase() === serviceName.toLowerCase()
    );

    if (service) {
      navigate("/result", {
        state: {
          searchInput: service,
        },
      });
    } else {
      console.error("Service not found!");
    }
  };

  const getFontSize = (lang: string) => {
    switch (lang) {
      case "as":
      case "bn":
      case "ml":
        return "text-responsive-sm md:text-responsive-base xl:text-responsive-lg"; 
      case "en":
        return ""; 
      default:
        return "text-responsive-xs md:text-responsive-sm xl:text-responsive-lg"; 
    }
  };

  const languageClass = getFontSize(i18n.language);
  return (
    <>
      <div className="xl:max-w-[1186px] mt-[80px] px-5 2xl:max-w-[1440px] mx-auto 4xl:max-w-full 4xl:px-[90px]">
        <div className="grid lg:grid-cols-2 mb-[80px] leading-normal mt-[28px]">
          <div className="flex flex-col justify-center">
            <h5 className="4xl:text-[1.2vw] 2xl:text-[23px] sm:text-[20px] text-[16px] text-[#5E6D76] font-medium lg:text-start text-center">
              {t("Get100safeservicebyverifiedpartners")}
            </h5>
            <h1
              className={`4xl:text-[2.2vw] 3xl:text-[70px] xl:text-[55px] sm:text-[40px] text-[32px] text-[#162634] font-bold 2xl:leading-[78px] lg:text-start text-center sm:mt-0 mt-4 4xl:mb-2 ${languageClass}`}
            >
              {t("whatServiceDoYouNeed")}
            </h1>

            {/* <span className="font-bold 2xl:text-[70px] lg:text-[55px] sm:text-[40px] text-[32px] text-[#162634] lg:text-start text-center">
              {" "}
              {t("doYouNeed")}
            </span> */}
            <div
              onClick={() => navigate("/home")}
              className="relative 4xl:max-w-[640px] lg:max-w-[470px] max-w-[500px] lg:mx-0 mx-auto w-full lg:mt-0 mt-4 mb-5"
            >
              <div className="absolute flex items-center justify-center top-0 bottom-0 w-[60px] h-full">
                <Megnify className={"#272D29"} />
              </div>
              <input
                className="border border-solid outline-none focus:outline-none h-[60px] pl-[60px] py-[18px] rounded-[14px] w-full placeholder:text-[#999485] text-black  border-[#B9B9B9] text-responsive-sm md:text-responsive-lg leading-[24px] font-medium placeholder:4xl:text-[1vw]"
                type="text"
                placeholder={placeholderText}
              />
            </div>
            <div className="flex flex-row flex-wrap justify-center gap-5 mt-4 lg:justify-start lg:mt-0">
              {/* <a className="underline text-[#272D29] font-medium cursor-pointer text-[17px] 4xl:text-[1vw]">
                {t("HomeRepair")}
              </a>
              <a className="underline text-[#272D29] font-medium cursor-pointer text-[17px] 4xl:text-[1vw]">
                {t("Auto Body Repair")}
              </a>
              <a className="underline text-[#272D29] font-medium cursor-pointer text-[17px] 4xl:text-[1vw]">
                {t("ElectricianAssembly")}
              </a> */}

              <button
                className="underline text-[#272D29] font-medium cursor-pointer text-[17px] 4xl:text-[1vw] bg-transparent border-none p-0"
                onClick={() => handleServiceClick("Home")}
              >
                {t("HomeRepair")}
              </button>
              <button
                className="underline text-[#272D29] font-medium cursor-pointer text-[17px] 4xl:text-[1vw] bg-transparent border-none p-0"
                onClick={() => handleServiceClick("Auto Body Repair")}
              >
                {t("Auto Body Repair")}
              </button>
              <button
                className="underline text-[#272D29] font-medium cursor-pointer text-[17px] 4xl:text-[1vw] bg-transparent border-none p-0"
                onClick={() => handleServiceClick("Electrician")}
              >
                {t("ElectricianAssembly")}
              </button>
            </div>
          </div>
          <div className="mt-10 lg:mt-0">
            <img
              src={require("../assets/images/landing-img.png")}
              className="w-full 3xl:max-w-full max-w-[430px] mx-auto 4xl:max-w-[80%]"
              alt=""
            />
          </div>
        </div>
        {/* <div className="">
          <img
            src={require("../assets/images/ad-img.png")}
            className="w-full"
          />
        </div> */}
      </div>
      <div
        className="md:pt-[92px] md:pb-[126px] overflow-hidden relative"
        ref={compRef}
        id="compRef"
      >
        <div className="max-w-[1180px] mx-auto relative">
          <div className="absolute top-[-280px] left-[-100px]">
            <img
              src={require("../assets/images/Main.png")}
              className="w-full"
            />
          </div>
          <div>
            <AboutLanding />
          </div>
        </div>
      </div>
      <div ref={servicesRef} id="servicesRef">
        <OurServices
          benefitsRef={benefitsRef}
          serviceData={serviceData}
          targetId={targetId}
        />
      </div>
      <Needjob
        onRegisterClicked={() => onRegisterClicked(UserType.LABOURER)}
        user={user}
      />
      <div ref={solutionRef} className="mt-[64px] pt-10" id="solutionRef">
        <SolutionsandSupport />
      </div>
      <div>
        <Benefits
          onRegisterClicked={() => onRegisterClicked(UserType.EMPLOYER)}
          user={user}
        />
      </div>
      <ReplyQoutes />
      <DowloadApp />
      {showCookieConsentBanner ? (
        <CookieConsentBanner
          onCookieAcknowledged={() => {
            setShowCookieConsentBanner(false);
          }}
        />
      ) : null}
    </>
  );
};

export default Landing;
