import React from "react";
import { Dialog } from "@headlessui/react";
import { FaMapMarkerAlt, FaTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import LocationSelector from "../../pages/Location/LocationSelector";
import { classNames } from "../../utils/utils";
import { useLocationContext } from "../Context/LocationContext";
import { useUpdateLocation } from "../services/Mutation";
import { UserDetailResponse } from "../../API/types";
import { RiLoader2Fill } from "react-icons/ri";

interface LocationModalProps {
  isOpen: boolean;
  userData: UserDetailResponse | undefined;
  handleClose: () => void;
}

const LocationChangeModal: React.FC<LocationModalProps> = ({
  isOpen,
  userData,
  handleClose,
}) => {
  const { t } = useTranslation();
  const { state } = useLocationContext();
  const { selectedLocationData } = state;
  const updateLocationMutation = useUpdateLocation();

  const handleUpdate = async () => {
    if (
      selectedLocationData?.lat &&
      selectedLocationData?.lng &&
      selectedLocationData?.city
    ) {
      const locationData = {
        lat: selectedLocationData.lat,
        lng: selectedLocationData.lng,
        location: selectedLocationData.city,
      };

      try {
        await updateLocationMutation.mutateAsync(locationData);
        handleClose();
      } catch (error) {
        console.error("Error updating location:", error);
      }
    }
  };

  if (!isOpen) return null;

  return (
    <Dialog open={isOpen} onClose={() => {}} as="div" className="relative z-50">
      <div
        className="fixed inset-0 bg-black/50 backdrop-blur-sm transition-opacity duration-300"
        aria-hidden="true"
      />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="w-full max-w-md rounded-xl bg-white shadow-xl transform transition-all duration-300 ease-out">
          <div className="relative p-6 sm:p-8">
            <button
              onClick={handleClose}
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-700 hover:bg-gray-100 rounded-full p-2 transition-colors duration-300 focus:outline-none"
            >
              <FaTimes className="h-5 w-5 sm:h-6 sm:w-6" />
            </button>

            <Dialog.Title className="flex items-center gap-3 text-base sm:text-lg md:text-xl lg:text-2xl font-semibold mb-6 text-gray-900">
              <FaMapMarkerAlt className="h-6 w-6 sm:h-7 sm:w-7 md:h-8 md:w-8 text-[#F0A61F]" />
              {t("Update Your Location")}
            </Dialog.Title>

            <div className="space-y-6">
              <div>
                <label
                  htmlFor="old-location"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  {t("Current Location")}
                </label>
                <input
                  id="old-location"
                  type="text"
                  value={
                    (userData?.labourerDetails?.location ||
                      t("Location not provided")) ??
                    ""
                  }
                  disabled
                  className="w-full px-4 py-2 bg-gray-100 border border-gray-300 rounded-lg text-gray-600 focus:ring-2 focus:ring-[#F0A61F] focus:border-[#F0A61F] transition-colors duration-300"
                />
              </div>

              <div>
                <label
                  htmlFor="new-location"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  {t("Select New Location")}
                </label>
                <LocationSelector />
              </div>
            </div>

            <div className="mt-8 flex justify-center text-center items-center">
              <button
                onClick={handleUpdate}
                disabled={
                  !selectedLocationData?.lat ||
                  !selectedLocationData?.lng ||
                  updateLocationMutation.isLoading
                }
                className={classNames(
                  "items-center px-6 py-3 rounded-lg text-white  text-center transition-transform duration-200 w-full  ",
                  !selectedLocationData?.lat || !selectedLocationData?.lng
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-gradient-to-r from-[#F0A61F] to-[#e69900] hover:scale-105",
                  updateLocationMutation.isLoading ? "opacity-70 " : ""
                )}
              >
                {updateLocationMutation.isLoading ? (
                  <>
                    <div className="flex gap-2 justify-center items-center">
                      <div>{t("please wait")}</div>
                      <RiLoader2Fill className="ml-2 h-5 w-5 animate-spin" />
                    </div>
                  </>
                ) : (
                  t("Update Location")
                )}
              </button>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default LocationChangeModal;
