import React from "react";
import { BlueTrue } from "../../assets/images/AllSvg";
import { useTranslation } from "react-i18next";
import { AiFillCaretRight } from "react-icons/ai";
import { isAuthenticated } from "../../utils/jwtUtils";

const Benefits = ({
  user,
  onRegisterClicked
}: {
  user:any,
  onRegisterClicked: () => void;
}) => {
  const { t } = useTranslation();
  const userRoles = user?.roles;
  return (
    <div className="px-5">
      <div className="grid lg:grid-cols-2 gap-[67px] 4xl:max-w-[1930px] xl:max-w-[1186px] py-[124px] 2xl:max-w-[1440px] mx-auto">
        <div>
          <h2 className="text-[#162634] 2xl:text-responsive-2xl lg:text-responsive-lg] text-responsive-base font-bold mb-8 ">
            {t("benefitsOfBecomingAnEmployer")}
          </h2>
          <div>
            <ul>
              <li className="flex items-center md:gap-[33px] gap-[20px] mb-[36px]">
                <div className="min-w-[30px]">
                  <BlueTrue />
                </div>
                <span className="4xl:text-[30px] xl:text-[23px] md:text-[20px] text-[16px] text-black font-medium leading-[28px]">
                  {t("Most Affordable plans out there")}
                </span>
              </li>
              <li className="flex items-center md:gap-[33px] gap-[20px] mb-[36px]">
                <div className="min-w-[30px]">
                  <BlueTrue />
                </div>
                <span className="4xl:text-[30px] xl:text-[23px] md:text-[20px] text-[16px] text-black font-medium leading-[28px]">
                  {t("You keep 100% of the profit")}
                </span>
              </li>
              <li className="flex items-center md:gap-[33px] gap-[20px] mb-[36px]">
                <div className="min-w-[30px]">
                  <BlueTrue />
                </div>
                <span className="4xl:text-[30px] xl:text-[23px] md:text-[20px] text-[16px] text-black font-medium leading-[28px]">
                  {t("Find potential customers daily without a limit")}
                </span>
              </li>
              <li className="flex items-center md:gap-[33px] gap-[20px] mb-[36px]">
                <div className="min-w-[30px]">
                  <BlueTrue />
                </div>
                <span className="4xl:text-[30px] xl:text-[23px] md:text-[20px] text-[16px] text-black font-medium leading-[28px]">
                  {t("Easy contact features")}
                </span>
              </li>
            </ul>
            { !isAuthenticated() && (
            //   <p className="text-[16px] font-bold text-red-600">
            //     Already logged in, Please logout to register new.
            //   </p>
            // ) : (
              <button
                onClick={() => {
                  onRegisterClicked()
                }}
                className="flex items-center justify-center 4xl:text-[1vw] text-[15px] leading-[13px] font-medium 4xl:py-[23px] py-[13px] px-[16px] rounded-full bg-[#F0A61F]"
              >
                {t("Signup")}{" "}
                <AiFillCaretRight className="text-[14px] 4xl:text-[1vw]" />
              </button>
            )}
          </div>
        </div>
        <div>
          <img
            src={require("../../assets/images/businesswoman.png")}
            className="mx-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default Benefits;
