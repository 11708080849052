import React, { useState } from "react";
import { Dialog } from "@headlessui/react";
import { FaTimes, FaPhoneAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useChangePhoneNumber,
  useUpdateUserPhoneNumber,
} from "../services/Mutation";
import {
  ChangePhoneNumberRequest,
  ChangePhoneNumberResponse,
  UserDetailResponse,
} from "../../API/types";
import { OtpSchema } from "../../schema/OtpSchema";
import { PhoneSchema } from "../../schema/PhoneSchema";
import { toast } from "react-toastify";
import { RiLoader2Fill } from "react-icons/ri";
import OtpForm from "../OtpPhoneChangeComponent/OtpPhoneChangeComponent";

interface PhoneModalProps {
  isOpen: boolean;
  userData: UserDetailResponse | undefined;
  handleClose: () => void;
}

export enum PhoneStep {
  VERIFY_PHONE,
  VERIFY_OTP,
}

const PhoneChangeModal: React.FC<PhoneModalProps> = ({
  isOpen,
  userData,
  handleClose,
}) => {
  const { t } = useTranslation();
  const [step, setStep] = useState<PhoneStep>(PhoneStep.VERIFY_PHONE);
  const [requestId, setRequestId] = useState<number | null>(null);
  const [verificationId, setVerificationId] = useState<string | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);

  //PHONE NUMBER FORM SETUP
  const {
    register: registerPhone,
    reset: formReset,
    handleSubmit: handlePhoneSubmit,
    formState: { errors: phoneErrors },
  } = useForm({
    resolver: yupResolver(PhoneSchema),
    mode: "onChange",
  });

  //OTP FORM SETUP

  const {
    handleSubmit: handleOtpSubmit,
    setValue: setOtpValue,
    formState: { errors: otpErrors },
  } = useForm({
    resolver: yupResolver(OtpSchema),
    mode: "onChange",
  });

  const updatePhoneNumberMutation = useChangePhoneNumber();
  const updateUserPhoneNumberMutation = useUpdateUserPhoneNumber();

  // PHONE NUMBER SUBMISSION
  const onSubmitPhone = (data: { phone: string }) => {
    const phoneNumberData: ChangePhoneNumberRequest = {
      phoneNumber: data.phone,
    };

    // SEND PHONE NUMBER UPDATE REQUEST
    updatePhoneNumberMutation.mutate(phoneNumberData, {
      onSuccess: (response: ChangePhoneNumberResponse) => {
        toast.success(`OTP: ${response.otp}`);
        setRequestId(response.requestId);
        setVerificationId(response.verificationId);
        setPhoneNumber(data.phone);
        setStep(PhoneStep.VERIFY_OTP);
      },
    });
  };

  // FUNCTION TO HANDLE OTP VERIFICATION
  const onSubmitOtp = (data: { otp: string }) => {
    if (requestId === null || verificationId === null) {
      toast.error("Missing verification details.");
      return;
    }
    if (!phoneNumber) {
      toast.error("Phone number is required.");
      return;
    }
    const verificationData = {
      otp: data.otp,
      phoneNumber: phoneNumber,
      requestId: requestId,
      verificationId: verificationId,
    };

    // SEND OTP VERIFICATION REQUEST
    updateUserPhoneNumberMutation.mutate(verificationData, {
      onSuccess: () => {
        toast.success("OTP verification successful!");
        toast.info(
          "Your Phone number change request has been sent. The change will take effect once approved by the admin.",
          {
            position: "top-right",
            autoClose: 9000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            icon: <FaPhoneAlt className="h-5 w-5  text-[#F0A61F]" />,
            style: {
              backgroundColor: "#333",
              color: "#fff",
              fontWeight: "bold",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
              borderRadius: "8px",
            },
          }
        );
        formReset();
        handleClose();
      },
    });
  };

  if (!isOpen) return null;

  return (
    <Dialog open={isOpen} onClose={() => {}} as="div" className="relative z-50">
      <div
        className="fixed inset-0 bg-black/50 backdrop-blur-sm"
        aria-hidden="true"
      />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <form
          onSubmit={
            step === PhoneStep.VERIFY_PHONE
              ? handlePhoneSubmit(onSubmitPhone)
              : handleOtpSubmit(onSubmitOtp)
          }
          className="w-full max-w-md relative"
        >
          <Dialog.Panel className="w-full rounded-xl bg-white shadow-xl p-6 sm:p-8">
            <button
              onClick={handleClose}
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-700 hover:bg-gray-100 rounded-full p-2 transition-colors duration-300 focus:outline-none"
            >
              <FaTimes className="h-5 w-5 sm:h-6 sm:w-6" />
            </button>

            {/* Step 1:PHONE NUMBER VERIFICATION */}
            {step === PhoneStep.VERIFY_PHONE && (
              <>
                <Dialog.Title className="flex items-center gap-3 text-lg font-semibold text-gray-900 mb-6">
                  <FaPhoneAlt className="h-6 w-6 text-[#F0A61F]" />
                  {t("Change Your Phone Number")}
                </Dialog.Title>

                <div className="space-y-6">
                  <div>
                    <label
                      htmlFor="old-phone"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      {t("Current Phone Number")}
                    </label>
                    <input
                      id="old-phone"
                      type="text"
                      value={
                        (userData?.user?.phoneNumber ||
                          t("Phone number not provided")) ??
                        ""
                      }
                      disabled
                      className="w-full px-4 py-2 bg-gray-100 border border-gray-300 rounded-lg text-gray-600"
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="new-phone"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      {t("Enter New Phone Number")}
                    </label>
                    <input
                      id="new-phone"
                      type="tel"
                      {...registerPhone("phone")}
                      placeholder={t("New Phone Number") ?? ""}
                      className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-0 focus:border-[#F0A61F] ${
                        phoneErrors.phone ? "border-red-500" : "border-gray-300"
                      }`}
                    />

                    {phoneErrors.phone && (
                      <p className="text-red-600 text-sm mt-1">
                        {phoneErrors.phone.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="mt-8 flex justify-end">
                  <button
                    type="submit"
                    className={`flex items-center justify-center px-6 py-3 rounded-lg text-white text-center transition-transform duration-200 w-full bg-gradient-to-r from-[#F0A61F] to-[#e69900] hover:scale-105 ${
                      updatePhoneNumberMutation?.isLoading
                        ? "opacity-70 "
                        : ""
                    }`}
                    disabled={updatePhoneNumberMutation?.isLoading}
                    aria-busy={updatePhoneNumberMutation?.isLoading}
                    aria-live="polite"
                  >
                    {updatePhoneNumberMutation?.isLoading ? (
                      <>
                        <span>{t("please wait")}</span>
                        <RiLoader2Fill className="ml-2 h-5 w-5 animate-spin" />
                      </>
                    ) : (
                      t("Continue")
                    )}
                  </button>
                </div>
              </>
            )}

            {/* Step 2:OTP VERIFICATION */}

            {step === PhoneStep.VERIFY_OTP && (
              <OtpForm
                handleOtpSubmit={onSubmitOtp}
                handleClose={handleClose}
                isOpen={isOpen}
                isLoading={updateUserPhoneNumberMutation.isLoading}
                otpErrors={otpErrors}
                setValue={setOtpValue}
              />
            )}
          </Dialog.Panel>
        </form>
      </div>
    </Dialog>
  );
};

export default PhoneChangeModal;
