import React, { useRef, useState, KeyboardEvent, ChangeEvent } from "react";
import { useMutation } from "@tanstack/react-query";
import { Otptimer } from "otp-timer-ts";
import { useTranslation } from "react-i18next";
import { ResendOtpMutate, verifyOtpMutate } from "../../API/api";
import { ApiError, VerifyOtpRequest, VerifyOtpResponse } from "../../API/types";
import { toast } from "react-toastify";
import { setToken, setVerifiedToken } from "../../utils/jwtUtils";
import LoadingIndicator from "../LoadingIndicator";

interface OtpInputProps {
  onOtpVerificationSucess: (isNewUser: boolean) => void;
  initialValues: { phone: string; verificationId: string };
}

const OtpInputComponent: React.FC<OtpInputProps> = (props) => {
  const { t } = useTranslation();
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [otp, setOtp] = useState(Array(6).fill(""));

  const handleOtpChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    if (value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < 5) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    switch (e.key) {
      case "Backspace":
        if (!otp[index] && index > 0) {
          e.preventDefault();
          const newOtp = [...otp];
          newOtp[index - 1] = "";
          setOtp(newOtp);
          inputRefs.current[index - 1]?.focus();
        }
        break;
      case "Enter":
        e.preventDefault();
        verifyOtp();
        break;
      case "ArrowRight":
        if (index < inputRefs.current.length - 1) {
          e.preventDefault();
          inputRefs.current[index + 1]?.focus();
        }
        break;
      case "ArrowLeft":
        if (index > 0) {
          e.preventDefault();
          inputRefs.current[index - 1]?.focus();
        }
        break;
    }
  };
  const verifyOtp = () => {
    const otpString = otp.join("");
    mutationOtp.mutate({ ...props.initialValues, otp: otpString });
  };

  const mutationOtp = useMutation<
    VerifyOtpResponse,
    ApiError,
    VerifyOtpRequest,
    any
  >(verifyOtpMutate, {
    onSuccess: (data) => {
      if (data.accessToken) {
        if (data.isNewUser) {
          setVerifiedToken(data.accessToken);
        } else {
          setToken(data.accessToken);
          const cookie = {
            name: "jwt",
            value: data.refreshToken,
            expires: new Date(Date.now() + 90 * 24 * 60 * 60 * 1000),
            httpOnly: true,
          };
          document.cookie = JSON.stringify(cookie);
        }
        toast.success("OTP Verified Successfully.");
        props.onOtpVerificationSucess(data.isNewUser);
      }
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  const resendOtp = useMutation(ResendOtpMutate, {
    onSuccess: (data) => {
      if (data) {
        toast.success(data.otp);
        setOtp(Array(6).fill(""));
      }
    },
    onError(error: any) {
      toast.error(error.message);
    },
  });

  const handleResend = () => {
    resendOtp.mutate(props.initialValues);
  };

  return (
    <div className="max-w-[480px] h-[80vh] flex flex-col justify-center">
      <div className="items-center justify-between sm:flex">
        <img
          src={require("../../assets/images/verify.png")}
          alt="img"
          className="sm:hidden"
        />
        <div className="mt-6 sm:mt-0">
          <h1 className="text-[#272D29] md:text-[25px] lg:text-[36px] sm:text-[28px] text-[30px] font-bold">
            {t("OTPVerification")}
          </h1>
          <p className="text-[#696969] text-[15px]">{t("EnterOTPtoLogin")}</p>
        </div>
        <img
          src={require("../../assets/images/verify.png")}
          alt="img"
          className="sm:mt-[-100px] sm:block hidden md:w-[100px] lg:w-[150px] md:mt-[-50px]"
        />
      </div>
      <div className="grid grid-cols-6 sm:gap-2 md:gap-2 gap-2 mt-6 px-1 ">
        {otp.map((digit, index) => (
          <input
            key={index}
            ref={(el) => (inputRefs.current[index] = el)}
            value={digit}
            onChange={(e) => handleOtpChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            type="text"
            maxLength={1}
            className="h-12 text-center border border-[#D1D0CA] focus:border-[#F0A61F] focus:outline-none focus:ring-1 focus:ring-[#F0A61F] rounded"
          />
        ))}
      </div>

      <button
        onClick={verifyOtp}
        className="mt-8 bg-[#F0A61F] hover:bg-[#eea00f] text-white font-semibold rounded-md w-full h-12 flex items-center justify-center disabled:opacity-80"
        disabled={mutationOtp.isLoading}
      >
        {t("Verify")}
        {mutationOtp.isLoading && (
          <div className=" flex  ml-4 ">
            <LoadingIndicator color="white" />
          </div>
        )}
      </button>
      <div className="rounded flex justify-center text-gray-500 hover:text-gray-900 mt-[30px] w-full h-[44px]">
        <Otptimer
          minutes={1}
          text="Resend OTP:"
          buttonText="Resend OTP"
          buttonStyle={{
            backgroundColor: "#fff",
          }}
          textStyle={{
            color: "#808080",
            marginTop: "30px",
            justifyContent: "center",
          }}
          onResend={handleResend}
        />
      </div>
    </div>
  );
};

export default OtpInputComponent;
